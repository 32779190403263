import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FooterComponent } from './footer.component';
import { Utils, locationKeywords, searchKeywords } from './utils';
import { radii } from "./mock-data.component"
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SharedService } from './shared.service';
import { Title } from '@angular/platform-browser';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
  @ViewChild(FooterComponent) footerRef: FooterComponent
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  
  public radii: number[]
  public form: UntypedFormGroup
  public isMobileMenuHidden = true
  public locationAutosuggest: string[] = []
  public searchKeywordAutosuggest: string[] = []
  public whatsapp = Utils.whatsapp
  public whatsAppNumber = Utils.whatsAppNumber

  public get profession(): string | null { return this.form.get("profession")?.value }
  public get location(): string | null { return this.form.get("location")?.value }
  public get radius(): number | null { return this.form.get("radius")?.value }

  public constructor(
    private readonly elementRef: ElementRef,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private titleService: Title,
    ) {
      this.radii = radii
  }

  public get searchHistory(): string[] {
    if(localStorage.getItem("history")) {
      const history = JSON.parse(localStorage.getItem("history") ?? '')
      const profession = history?.profession as Array<string> ?? []
      if (profession?.[0] === "") { return [] }
      return profession
    }
    return []
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand")
    this.form = this.formBuilder.group({
      profession: this.formBuilder.control(this.sharedService?.filterData?.profession ?? ""),
      location: this.formBuilder.control(this.sharedService?.filterData?.location ?? ""),
      radius: this.formBuilder.control(0),
    })
    this.form.get("location")?.valueChanges.subscribe((i: string) => {
      this.locationAutosuggest = []
      if(i.trim().toLowerCase() === "") { return }

      for(const a of locationKeywords) {
        const r = a.slice(0, i.length).toLowerCase()
        if(i.toLowerCase().includes(r)) {
          this.locationAutosuggest.push(a)
        }
      }
    })
    this.form.get("profession")?.valueChanges.subscribe((i: string) => {
      this.searchKeywordAutosuggest = []
      if(i.trim().toLowerCase() === "") { return }

      for(const a of searchKeywords) {
        const r = a.slice(0, i.length).toLowerCase()
        if(i.toLowerCase().includes(r)) {
          this.searchKeywordAutosuggest.push(a)
        }
      }
    })
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()
    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    window.addEventListener("keypress", (event) => {
      if(event.key === "Enter") {
        this.search()
        this.autocomplete.closePanel();
      }
    })
    this.scrollFade()
  }

  public clearProfession(): void {
    this.form.patchValue({ profession: "" })
  }

  public clearLocation(): void {
    this.form.patchValue({ location: "" })
  }

  public showDivider(index: number): boolean {
    return index < this.searchHistory.length - 1
  }

  public setProfession(word: string): void {
    this.form.patchValue({ profession: word })
  }

  public search(): void {
    this.sharedService.filterData.profession = this.profession ?? ""
    this.sharedService.filterData.location = this.location ?? ""
    Utils.saveSearchHistory(this.profession ?? "")
      this.router.navigate(["vacancies"], { relativeTo: this.activatedRoute })
    
  }

  public toggleMobileMenu(): void {
    this.isMobileMenuHidden = !this.isMobileMenuHidden
    document.body.style.position = this.isMobileMenuHidden ? "" : "fixed"
  }

  public routeTo(menuItem: string, isMobileMenu: boolean = false): void {
    this.router.navigate([`${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
  }

  private scrollFade(): void {
    const connecting = (this.elementRef.nativeElement as HTMLElement).querySelector(".connecting")

    if(connecting && connecting.getBoundingClientRect().y < (window.innerHeight + 300) && connecting.classList.contains("scroll-fade")) {
      connecting!.classList.remove("scroll-fade")
    }
  }
}
