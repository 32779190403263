<div class="about">

</div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text">Over ons</div>
  </div>
</div>

<div class="container-1">
  <div #containerRef class="container-2 scroll-fade">
    <div class="left">
      <div class="title">Blauwtand</div>
      <div class="information">
        <div>
          <div class="paragraph-1">Blauwtand is gespecialiseerd in het verbinden van bedrijven en IT Professionals, door de connectie te maken tussen wensen en eisen van beide partijen. Van proces tot techniek: wij begrijpen, doorgronden en verbinden.</div>
          <div class="paragraph-1">Met ruim 20 jaar ervaring in de detachering en deta-vast constructies weten wij bij Blauwtand als geen ander hoe wij de juiste professionals en organisaties samen kunnen brengen. Aan de hand van persoonlijke bemiddeling gaan wij voor één doel: een duurzame en lange termijn samenwerking.</div>
        </div>
      </div>
      <div class="about-image">
        <!-- <img class="about-image" src="./assets/IMG_0944 - Bar.JPEG" alt="Voor opdrachtgevers"> -->
      </div>
    </div>
  </div>
</div>

