import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FooterComponent } from './footer.component';
import { SharedService } from './shared.service';
import { Utils } from './utils';
import { filter } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(FooterComponent) footerRef: FooterComponent
  @ViewChild("transitionRef") transitionRef: ElementRef
  
  public menuItems: string[] = [
    "Voor professionals", 
    "Voor opdrachtgevers", 
    "Over ons", 
    "Contact", 
  ]
  public isMobileMenuHidden = true
  public isBrowserRefreshed = false
  public redirectTo: (name: string) => void = Utils.redirectTo

  public get hasCookieConsent() {
    const cookie = this.cookiesToMap()?.get("cookie_consent")
    return cookie === "true" || cookie === "false"
  }

  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly titleService: Title,
    public readonly sharedService: SharedService,
    ) {}
  
  public ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.googleTagManagerService.pushTag({
            event: 'page_view',
            page_path: event.url,
            page_title: this.titleService.getTitle(),
          });
        }, 100)
      }
    });
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()
    window.addEventListener("resize", () => {
      if(window.innerWidth >= 1024) {
        this.isMobileMenuHidden = true
      }
    }) 
  }

  public toggleMobileMenu(): void {
    this.isMobileMenuHidden = !this.isMobileMenuHidden
  }

  public navigateTo(menuItem: string, isMobileMenu: boolean = false): void {
    if(menuItem) {
      switch(menuItem) {
        case "Over ons": menuItem = "about"; break
        case "Voor opdrachtgevers": menuItem = "employers"; break
        case "Voor professionals": menuItem = "vacancies"; break
      }

      if(this.router.url.includes(menuItem.toLowerCase()) || (this.router.url == "/" && menuItem == "home")) {
        this.router.navigate([`${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
      } else {
        this.router.navigate([`${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
      }
    }

    if(isMobileMenu) {
      this.toggleMobileMenu()
    }

    if(menuItem === "home") {
      this.isMobileMenuHidden = true
    }
  }

  public navigateHome() {
    if(this.router.url == "/") {
      this.router.navigate(["home"])
    } else {
      this.router.navigate(["home"])
    }
  }

  public navigatePrivacyStatement() {
    this.router.navigate(["privacy-statement"])
  }

  public cookiesReadMore() {
    window.open("https://www.cookiesandyou.com/")
  }

  public setCookieConsent(value: string) {
    document.cookie = `cookie_consent=${value};max-age=${60 * 60 * 24 * 365}`
  }

  private cookiesToMap() {
    const cookiesArray = document.cookie.split(";").map(c => c.split('='))
    const cookiesMap = new Map<string, string>()
    cookiesArray.forEach(i => { cookiesMap.set(i?.[0]?.trim(), i?.[1]?.trim()) })
    return cookiesMap
  }
}
