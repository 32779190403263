export interface Location {
  city: string
  postalCode: string
  state: string
}

export interface Recruiter {
  firstName: string
  lastName: string
  userName: string
  phoneNumber?: string
}

export class Salary {
  public min: number
  public max: number
  public static: number
}

export interface JobCatsOne {
  industry: string
  countryCode: string
  dateCreated: Date
  dateModified: Date
  description: string
  id: number
  location: Location
  title: string
  salary: Salary
  education: string
  shortDescription: string
  employment: string
  hours: string[]
  industries: string[]
  publicationDate: Date
  recruiter?: Recruiter
  directMatch?: boolean
}
