<div class="logs">

</div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text">API insights</div>
  </div>
</div>

<div class="container-1" [formGroup]="form">
  <div #containerRef class="container-2 scroll-fade">
    <div class="content">
      <div class="header-container">
        <div class="header">API calls</div>
        <div class="sub-header">Laatste <span class="blue-1">24</span> uur.</div>
      </div>
      
      <ng-container *ngIf="hasChartData; else noChartData">
        <div class="chart-container">
          <div class="container">
            <canvas class="canvas" id="canvas1" #chartAllRef></canvas>
            <canvas class="canvas" id="canvas2" #chartVacanciesRef></canvas>
            <canvas class="canvas" id="canvas3" #chartDirectApplyRef></canvas>
          </div>
        </div>
      </ng-container>
      <ng-template #noChartData>
        <div class="no-data">Er kon geen data worden gevonden.</div>
      </ng-template>

      <div class="header-container">
        <div class="header">Live logs</div>
        <div class="sub-header">Maximaal <span class="blue-1">250</span> weergaven.</div>
      </div>
      <div class="log-search">
        <mat-form-field appearance="fill">
          <mat-label>Zoeken</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput type="text" formControlName="search">
          <button *ngIf="hasSearch()" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
            <icon name="close"></icon>
          </button>
        </mat-form-field>
      </div>
      <div #logContainerRef class="log-container" *ngIf="filteredLogs.length > 0; else notFound">
        <div class="log-item" *ngFor="let log of filteredLogs">
          <ng-container *ngIf="isMobile; else notMobile">
            <div class="log-item-mobile">
              <div class="log-item-mobile-time">
                <div class="log-date">{{ log.date }}</div>
                <div class="log-time">{{ log.time }}</div>
              </div>
              <div class="log-text">{{ log.content }}</div>
            </div>
          </ng-container>
          <ng-template #notMobile>
            <div class="log-date">{{ log.date }}</div>
            <div class="log-time">{{ log.time }}</div>
            <div class="log-text">{{ log.content }}</div>
          </ng-template>
        </div>
      </div>
      <ng-template #notFound>
        <div class="log-container">
          <div class="log-item">
            <div class="log-date">Er konden geen logs worden gevonden.</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

