import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface FilterData {
  profession: string,
  location: string,
  datePosted?: string,
  education?: string,
  salary?: string,
  employment?: string,
  hours?: string
  industries?: string
}

@Injectable({ providedIn: "root" })
export class SharedService {
  public isMobileMenuButtonHidden = false
  public selectedJobId: number
  public filterData: FilterData
  public transitionSubject = new Subject<boolean>()
  public transitionDelay = 250

  public constructor() {
    this.resetFilterData()
  }

  public resetFilterData() {
    this.filterData = {
      profession: "",
      location: ""
    }
  }
}