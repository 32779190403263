import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from './shared.service';
import { Utils } from './utils';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterViewInit {
  @ViewChild("containerRef") containerRef: ElementRef
  
  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private titleService: Title,
    private sharedService: SharedService
  ) {}
  
  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — Over ons")
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()

    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    this.scrollFade()
  }

  private scrollFade(): void {
    const container = this.containerRef.nativeElement as HTMLElement

    if(container && container.getBoundingClientRect().y < window.innerHeight && container.classList.contains("scroll-fade")) {
      container.classList.remove("scroll-fade")
    }
  }

  public navigateTo(menuItem: string, isMobileMenu: boolean = false): void {
    if(menuItem) {
      setTimeout(() => {
        this.router.navigate([`../${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
      }, this.sharedService.transitionDelay)
    }
  }
}
