<div class="menu-mobile" [ngClass]="{
  'hidden': isMobileMenuHidden
}">
  <div class="menu-item-container">
    <div class="menu-item" *ngFor="let menuItem of menuItems" (click)="navigateTo(menuItem, true)">{{menuItem}}</div>
  </div>
  <div class="line"></div>
  <div class="socials">
    <icon name="facebook" (click)="redirectTo('facebook')"></icon>
      <!-- <icon name="twitter"  (click)="redirectTo('twitter')"></icon> -->
      <icon name="linkedin" (click)="redirectTo('linkedin')"></icon>
      <icon name="instagram" class="insta"  (click)="redirectTo('instagram')"></icon>
  </div>
</div>

<div #transitionRef class="top-container">
  <div class="header-container" [ngClass]="{'header-hidden': sharedService.isMobileMenuButtonHidden}">
    <div class="header">
      <div class="header-logo" (click)="navigateTo('home')">
        <icon name="blauwtand"></icon>
      </div>
      <div class="header-menu-items" *ngFor="let menuItem of menuItems; let i = index" (click)="navigateTo(menuItem)">
        <div class="header-menu-item">
          <button class="header-button">
            <span>{{menuItem}}</span>
          </button>
          <div class="header-menu-item-divider" *ngIf="i !== menuItems.length -1"></div>
        </div>
      </div>
      <div class="header-menu-mobile" (click)="toggleMobileMenu()">
        <div [ngClass]="{'hidden': !isMobileMenuHidden}">
          <icon name="menu"></icon>
        </div>
      </div>
    </div>
</div>
<router-outlet class="content"></router-outlet>
<footer class="footer"></footer>

<div class="cookie-consent" [ngClass]="{'hidden': hasCookieConsent}">
  <div class="cookie-text">
    <!-- <div>Mogen wij cookies plaatsen? Lees meer over</div> -->
    <span class="cookie-hover right" (click)="navigateHome()">Blauwtand.com</span>
    <span>gebruikt cookies om de noodzakelijke websitefunctionaliteit te bieden en uw ervaring te verbeteren. Door de website te gebruiken, gaat u akkoord met onze</span>
    <span class="cookie-hover both" (click)="navigatePrivacyStatement()">privacy statement</span>
    <span>en het gebruik van</span>
    <span class="cookie-hover left" (click)="cookiesReadMore()">cookies</span>
    <span>.</span>
  </div>
  <div class="cookie-buttons">
    <button mat-raised-button color="primary" class="cookie-button-accept" (click)="setCookieConsent('true')">
      <span>Accepteren</span>
      <icon name="done"></icon>
    </button>
    <button mat-raised-button color="primary" class="secondary-button" (click)="setCookieConsent('false')">
      <span>Afwijzen</span>
      <icon name="close"></icon>
    </button>
  </div>
</div>