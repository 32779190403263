import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from './utils';

@Component({
  selector: 'privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit, AfterViewInit {
  @ViewChild("containerRef") containerRef: ElementRef
  // @ViewChild("contactRef") contactRef: ElementRef
  
  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {}
  
  email = Utils.email
  bullets_1: string[]
  bullets_2: string[]
  bullets_3: string[]
  bullets_4: string[]
  bullets_5: string[]
  bullets_6: string[]
  bullets_7: string[]
  bullets_8: string[]
  bullets_9: string[]
  
  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — Privacy statement")
    this.bullets_1 = [
      "Wie zijn wij?",
      "Wanneer verzamelen wij persoonsgegevens",
      "Waarom verzamelen wij uw persoonsgegevens",
      "Welke persoonsgegevens verzamelen wij van u",
      "Met wie kunnen wij uw persoonsgegevens delen",
      "Verwerken wij persoonsgegevens buiten Nederland?",
      "Uw rechten",
      "Beveiliging",
      "Bewaartermijn",
      "Technische vragen en cookies",
      "Vragen, opmerkingen of klachten",
      "Wijzigingen",
    ]
    this.bullets_2 = [
      "bij het bezoeken van één van onze websites,",
      "wanneer u uw gegevens achterlaat op één van onze websites,",
      "wanneer u uw gegevens invult of achterlaat op één van onze vestigingen,",
      "wanneer u zich op andere wijze aanmeldt of wordt aangemeld om gebruik te maken van onze dienstverlening,",
      "wanneer u contact hebt met een medewerker van Blauwtand IT Detachering;",
      "in het kader van een zakelijke relatie met Blauwtand IT Detachering.",
    ]
    this.bullets_3 = [
      "Voor het doen van aanbiedingen voor en/of verstrekken en uitwisselen van informatie over en ten behoeve van de dienstverlening en overige activiteiten van de verwerking verantwoordelijke.",
      "Voor de beoordeling van de geschiktheid en de beschikbaarheid van werkzoekenden en kandidaten, gedetacheerden, (tijdelijke) werknemers en zelfstandig ondernemers, voor en/of naar bemiddeling tot vast/tijdelijk werkaanbod dan wel een opdracht.",
      "In het kader van een overeenkomst met de werkzoekende/werknemer/zelfstandig ondernemer. Voor de voorbereiding, totstandkoming, uitvoering en beëindiging van (een) eventuele uitzend-, detacherings- of arbeidsovereenkomst(en), stageovereenkomst dan wel overeenkomst van opdracht.",
      "In het kader van een overeenkomst met de opdrachtgever. Voor de voorbereiding, totstandkoming, uitvoering en beëindiging van overeenkomsten met opdrachtgevers voor selectie, terbeschikkingstelling en/of inhuur van werkzoekenden en kandidaten, gedetacheerden, (tijdelijke) werknemers en zelfstandig ondernemers.",
      "Personeels- en salarisadministratie.",
      "In het kader van een overeenkomst met een leverancier. Voor de voorbereiding, totstandkoming, uitvoering en beëindiging van de overeenkomst met leveranciers die werkzoekenden, kandidaten, gedetacheerden, (tijdelijke) werknemers en zelfstandig ondernemers werven, selecteren of ter beschikking stellen aan (via) verantwoordelijke (bij opdrachtgever).",
      "Voor training, opleiding en andere vormen van loopbaanbegeleiding en persoonlijke ontwikkeling.",
      "Ter ontwikkeling en verbetering van onze dienstverlening. Door de manier waarop u onze producten en diensten gebruikt te analyseren leren we u beter kennen en komen we erachter waar en hoe we ons kunnen verbeteren. Bijvoorbeeld, we analyseren het resultaat van onze marketingactiviteiten om te meten hoe effectief deze zijn en hoe relevant onze campagnes zijn.",
      "Om te informeren over het management, het (doen) verzorgen van interne controle, bedrijfsveiligheid en het uitoefenen van audits en accountantscontrole.",
      "Wet- en regelgeving. Voor identificatie, bestrijding van fraude, verzorging van interne controle en bedrijfsveiligheid, aanvragen subsidies, afdrachtverminderingen, premievrijstellingen en/of premiekortingen en overige wet en regelgeving.",
    ]
    this.bullets_4 = [
      "De uitvoering van een overeenkomst",
      "Het voldoen aan een wettelijke verplichting",
      "Gerechtvaardigd belang",
      "Uw toestemming",
    ]
    this.bullets_5 = [
      "Gegevens betreffende het apparaat dat u gebruikt om onze website te bezoeken, zoals een IP-adres",
      "Strikt noodzakelijke en vrijwillig mee ingestemde cookie- en soortgelijke gegevens (zie ons Cookiebeleid)",
      "Op onze website of sociale media account vrijwillig gepubliceerde gegevens (via sociale netwerken, reactie bij een blog, et cetera)",
      "Via onze website vrijwillig ingevulde of achtergelaten gegevens (via een contactformulier, een aanmeldformulier, etc.)",
    ]
    this.bullets_6 = [
      "NAW gegevens, e-mailadres, telefoonnummer en andere contactgegevens",
      "Geboortegegevens, geslacht",
      "CV, informatie over opleiding, stages en werkervaring",
      "Pasfoto (indien toegevoegd aan het cv)",
      "Gegevens over trainingen en opleidingen en/of testen die u zelf of via ons hebt gevolgd of gedaan",
      "Gegevens over beschikbaarheid",
      "Alle gegevens die kandidaten zelf via hun motivatiebrief, cv of op andere wijze met Blauwtand IT Detachering delen",
      "Andere gegevens die van belang (kunnen) zijn in het kader van de beoordeling van de geschiktheid van de kandidaat, bijvoorbeeld referenties en getuigschriften",
      "Communicatie",
    ]
    this.bullets_7 = [
      "NAW gegevens, e-mailadres, telefoonnummer en andere contactgegevens",
      "Geboortegegevens, leeftijd, geslacht",
      "CV, informatie over opleiding, stages en werkervaring",
      "Gegevens over trainingen en opleidingen en/of testen die u zelf of via ons hebt gevolgd of gedaan",
      "Gegevens over beschikbaarheid",
      "Andere gegevens die van belang (kunnen) zijn in het kader van de beoordeling van de geschiktheid van de kandidaat, bijvoorbeeld referenties en getuigschriften",
      "Nationaliteit, BSN-nummer & identiteitsbewijs, werkvergunning",
      "Overige gegevens die gerelateerd zijn aan de personeels-, salaris- en verzuimregistratie",
      "Communicatie",
    ]
    this.bullets_8 = [
      "Zakelijke contactgegevens",
      "Persoonsgegevens die u ons verstrekt",
    ]
    this.bullets_9 = [
      "Het recht op inzage. Dit betekent dat u een verzoek kan doen om inzage te krijgen in de persoonsgegevens die wij van u verzameld hebben;",
      "Het recht op rectificatie of verbetering van uw gegevens indien deze onjuist of onvolledig zijn;",
      "Het recht op verwijdering van uw persoonsgegevens. Daarbij dient u er rekening mee te houden dat er zich omstandigheden kunnen voordoen waarin wij verplicht zijn uw gegevens te bewaren om aan onze juridische en wettelijke verplichtingen te voldoen;",
      "Het recht om bezwaar te maken tegen of te verzoeken om beperking van de verwerking van uw persoonsgegevens. Nogmaals, er kunnen zich omstandigheden voordoen waarin wij wettelijk bevoegd zijn om niet aan uw verzoek te voldoen;",
      "Het recht op dataportabiliteit. Dit houdt in dat u het recht heeft uw gegevens te ontvangen in een gestructureerde, gangbare en machineleesbare vorm. Daarbij heeft u het recht deze gegevens aan een andere verwerking verantwoordelijke over te dragen;",
      "Het recht om een klacht in te dienen bij een toezichthouder;",
      "Het recht om de gegeven toestemming in te trekken. Het intrekken van uw toestemming werkt niet met terugwerkende kracht, het intrekken van toestemming is enkel van toepassing op toekomstige verwerkingen.",
    ]
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()

    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    this.scrollFade()
  }

  private scrollFade(): void {
    const container = this.containerRef.nativeElement as HTMLElement

    if(container && container.getBoundingClientRect().y < window.innerHeight && container.classList.contains("scroll-fade")) {
      container.classList.remove("scroll-fade")
    }
  }
}
