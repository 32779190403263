import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SocketIoService {
  private readonly _logSubject = new Subject<string>();
  private readonly _apiCallAllSubject = new Subject<{ id: number, amount: number, endpoint: string, timestamp: string }[]>();
  private readonly _apiCallVacanciesSubject = new Subject<{ id: number, amount: number, endpoint: string, timestamp: string }[]>();
  private readonly _apiCallDirectApplySubject = new Subject<{ id: number, amount: number, endpoint: string, timestamp: string }[]>();

  constructor(private socket: Socket) {
    this.setupSocketListener();
  }

  private setupSocketListener() {
    this.socket.on('log', (data: any) => {
      if (Array.isArray(data) && Object.keys(data[0] || {}).length > 0 && data[0].hasOwnProperty("endpoint")) {
        switch (data[0].endpoint) {
          case "all": this._apiCallAllSubject.next(data); break;
          case "vacancies": this._apiCallVacanciesSubject.next(data); break;
          case "direct-apply": this._apiCallDirectApplySubject.next(data); break;
        }
      } else {
        this._logSubject.next(data);
      }
    });
  }

  getLogs(): Observable<string> {
    return this._logSubject.asObservable();
  }

  getApiCallAll() {
    return this._apiCallAllSubject.asObservable();
  }

  getApiCallVacancies() {
    return this._apiCallVacanciesSubject.asObservable();
  }

  getApiCallDirectApply() {
    return this._apiCallDirectApplySubject.asObservable();
  }

  joinRoom(key: string, room: string): void {
    this.socket.ioSocket.io.opts.query = { key, room }
    this.socket.emit('joinRoom', { key, room });
  }
}
