import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FooterComponent } from './footer.component';
import { SharedService } from './shared.service';
import { Utils } from './utils';

@Component({
  selector: 'employers',
  templateUrl: './employers.component.html',
  styleUrls: ['./employers.component.scss']
})
export class EmployersComponent implements OnInit, AfterViewInit {
  @ViewChild("containerRef") containerRef: ElementRef
  @ViewChild("contactRef") contactRef: ElementRef

  email = Utils.email
  phoneCall = Utils.phoneCall
  phoneNumber = Utils.phoneNumber
  
  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private titleService: Title,
    private sharedService: SharedService
  ) {}
  
  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — Voor opdrachtgevers")
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()

    window.addEventListener("scroll", (event) => {
      this.scrollFade()
    })
    this.scrollFade()
  }

  private scrollFade() {
    const container = this.containerRef.nativeElement as HTMLElement
    const contact = this.contactRef.nativeElement as HTMLElement
      
      if(container && container.getBoundingClientRect().y < window.innerHeight && container.classList.contains("scroll-fade")) {
        container.classList.remove("scroll-fade")
      }
      
      if(contact && contact.getBoundingClientRect().y < window.innerHeight && contact.classList.contains("scroll-fade")) {
        contact.classList.remove("scroll-fade")
      }
  }

  public navigateTo(menuItem: string, isMobileMenu: boolean = false) {
    if(menuItem) {
      this.router.navigate([`../${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
    }
  }
}
