import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about.component';
import { AppComponent } from './app.component';
import { ApplyComponent } from './apply.component';
import { ContactComponent } from './contact.component';
import { DirectApplyComponent } from './direct-apply.component';
import { EmployersComponent } from './employers.component';
import { MainComponent } from './main.component';
// import { NotFoundComponent } from './not-found.component';
import { PrivacyComponent } from './privacy.component';
import { VacanciesComponent } from './vacancies.component';
import { ViewJobComponent } from './view-job.component';
import { LogsComponent } from './logs/logs.component';
import { AppliedComponent } from './components/applied.component';

const routes: Routes = [
  { path: "", component: MainComponent },
  { path: "vacancies", component: VacanciesComponent },
  { path: "vacancies/view-job/:id", component: ViewJobComponent },
  { path: "vacancies/view-job/:id/direct-apply", component: DirectApplyComponent },
  { path: "vacancies/view-job/:id/direct-apply/success", component: AppliedComponent },
  { path: "vacancies/apply", component: ApplyComponent },
  { path: "employers", component: EmployersComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  { path: "privacy-statement", component: PrivacyComponent },
  { path: "api-insights", component: LogsComponent },
  { path: "home", redirectTo: "/", pathMatch: "full" },
  { path: "**", component: MainComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class RoutingModule { }
