<div class="contact">

</div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text">Contact</div>
  </div>
</div>

<div #containerRef class="container-1 scroll-fade">
  <div class="container-2">
    <div class="left">
      <div class="title">Blauwtand</div>
      <div class="information">
        <div>Bredewater 26</div>
        <div>2715 CA Zoetermeer</div>
      </div>
      <div class="buttons">
        <!-- <button mat-raised-button color="primary" class="phone-call" (click)="phoneCall('0798885551')"> -->
        <div class="info-child">
          <icon name="call"></icon>
          <div (click)="phoneCall('31798080320')">{{ phoneNumber }}</div>
        </div>
        <div class="info-child">
          <icon name="whatsapp"></icon>
          <div (click)="whatsapp('31798080328')">{{ whatsAppNumber }}</div>
        </div>
        <div class="info-child">
          <icon name="email"></icon>
          <div (click)="email('info@blauwtand.com')">info&#64;blauwtand.com</div>
        </div>
        <div class="info-child">
          <icon name="location_on"></icon>
          <div (click)="googleMapsOffice()">Blauwtand</div>
        </div>
        <!-- <button mat-raised-button color="primary" class="email" (click)="phoneCall('0798885551')">
          <icon name="email"></icon>
          <span>info@blauwtand.com</span>
        </button>
        <button mat-raised-button color="primary" class="google-maps" (click)="googleMapsOffice()">
          <icon name="location_on"></icon>
          <span>Open navigatie</span>
        </button> -->
      </div>
    </div>
    <!-- <div class="right">
    </div> -->

    <picture class="picture-right">
      <!-- <source srcset="./assets/blauwtand_development_environment_cropped.webp" type="image/webp">
      <source srcset="./assets/blauwtand_development_environment_cropped.png" type="image/png">  -->
      <!-- <img class="right" src="./assets/blauwtand_development_environment_cropped.png" alt="Contact"> -->
      <img class="right" src="./assets/IMG_0933 - Groep contactpagina.JPEG" alt="Contact">
    </picture>
    </div>
  </div>