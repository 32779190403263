import { Component, Input, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() name: string

  public constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon("logo", sanitizer.bypassSecurityTrustResourceUrl("assets/logo.svg"))
    iconRegistry.addSvgIcon("facebook", sanitizer.bypassSecurityTrustResourceUrl("assets/facebook.svg"))
    iconRegistry.addSvgIcon("youtube", sanitizer.bypassSecurityTrustResourceUrl("assets/youtube.svg"))
    iconRegistry.addSvgIcon("twitter", sanitizer.bypassSecurityTrustResourceUrl("assets/twitter.svg"))
    iconRegistry.addSvgIcon("linkedin", sanitizer.bypassSecurityTrustResourceUrl("assets/linkedin.svg"))
    iconRegistry.addSvgIcon("menu", sanitizer.bypassSecurityTrustResourceUrl("assets/menu.svg"))
    iconRegistry.addSvgIcon("search", sanitizer.bypassSecurityTrustResourceUrl("assets/search.svg"))
    iconRegistry.addSvgIcon("call", sanitizer.bypassSecurityTrustResourceUrl("assets/call.svg"))
    iconRegistry.addSvgIcon("location_on", sanitizer.bypassSecurityTrustResourceUrl("assets/location_on.svg"))
    iconRegistry.addSvgIcon("navigate_next", sanitizer.bypassSecurityTrustResourceUrl("assets/navigate_next.svg"))
    iconRegistry.addSvgIcon("business", sanitizer.bypassSecurityTrustResourceUrl("assets/business.svg"))
    iconRegistry.addSvgIcon("work", sanitizer.bypassSecurityTrustResourceUrl("assets/work.svg"))
    iconRegistry.addSvgIcon("schedule", sanitizer.bypassSecurityTrustResourceUrl("assets/schedule.svg"))
    iconRegistry.addSvgIcon("school", sanitizer.bypassSecurityTrustResourceUrl("assets/school.svg"))
    iconRegistry.addSvgIcon("tune", sanitizer.bypassSecurityTrustResourceUrl("assets/tune.svg"))
    iconRegistry.addSvgIcon("done", sanitizer.bypassSecurityTrustResourceUrl("assets/done.svg"))
    iconRegistry.addSvgIcon("close", sanitizer.bypassSecurityTrustResourceUrl("assets/close.svg"))
    iconRegistry.addSvgIcon("blauwtand", sanitizer.bypassSecurityTrustResourceUrl("assets/blauwtand.svg"))
    iconRegistry.addSvgIcon("sentiment_dissatisfied", sanitizer.bypassSecurityTrustResourceUrl("assets/sentiment_dissatisfied.svg"))
    iconRegistry.addSvgIcon("email", sanitizer.bypassSecurityTrustResourceUrl("assets/email.svg"))
    iconRegistry.addSvgIcon("euro", sanitizer.bypassSecurityTrustResourceUrl("assets/euro.svg"))
    iconRegistry.addSvgIcon("hourglass", sanitizer.bypassSecurityTrustResourceUrl("assets/hourglass.svg"))
    iconRegistry.addSvgIcon("whatsapp", sanitizer.bypassSecurityTrustResourceUrl("assets/whatsapp.svg"))
    iconRegistry.addSvgIcon("account_circle", sanitizer.bypassSecurityTrustResourceUrl("assets/account_circle.svg"))
    iconRegistry.addSvgIcon("file_upload", sanitizer.bypassSecurityTrustResourceUrl("assets/file_upload.svg"))
    iconRegistry.addSvgIcon("file", sanitizer.bypassSecurityTrustResourceUrl("assets/file.svg"))
    iconRegistry.addSvgIcon("instagram", sanitizer.bypassSecurityTrustResourceUrl("assets/instagram.svg"))
  }
  public ngOnInit(): void {}
}
