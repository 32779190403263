import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'applied',
  templateUrl: './applied.component.html',
  styleUrls: ['./applied.component.scss'],
})
export class AppliedComponent implements OnInit {
  public time = 10;
  public interval: any;
  public get countdown(): number {
    return this.time <= 0 ? 0 : this.time;
  }

  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.titleService.setTitle('Blauwtand — Sollicitie succesvol!');

    this.interval = setInterval(() => {
      this.time -= 1;
      if (this.time <= 0) {
        if (this.interval) {
          clearInterval(this.interval);
        }
        this.navigateTo('home');
      }
    }, 1000);
  }

  navigateTo(menuItem: string) {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (menuItem) {
      this.router.navigate([`../../../../${menuItem}`], {
        relativeTo: this.activatedRoute,
      });
    }
  }
}
