
<!-- <view-job 
  (closeJob)="toggleJobMenu()"
  [job]="selectedJob"
  [ngClass]="{
    'hidden': isJobMenuHidden
  }">
</view-job> -->

<div [formGroup]="form">

  <div class="menu-filter" [ngClass]="{
    'hidden': isFilterMenuHidden
  }">
    <div class="menu-filter-close" (click)="toggleFilterMenu()">
      <icon name="close"></icon>
    </div>
    <div class="menu-filter-container">
      <mat-form-field class="filter-option" appearance="outline">
        <mat-label>Datum geplaatst</mat-label>
        <select matNativeControl formControlName="datePosted">
          <option *ngFor="let datePostedOption of dates" [value]="datePostedOption">
            {{datePostedOption}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="outline">
        <mat-label>Functiegebieden</mat-label>
        <mat-select formControlName="industries" multiple>
          <mat-option *ngFor="let industryOption of industryOptions" [value]="industryOption">
            {{industryOption}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="outline">
        <mat-label>Opleidingsniveau</mat-label>
        <select matNativeControl formControlName="education">
          <option *ngFor="let educationalLevel of educationalLevels" [value]="educationalLevel">
            {{educationalLevel}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="outline">
        <mat-label>Minimum salaris</mat-label>
        <select matNativeControl formControlName="salary">
          <option *ngFor="let salaryType of salaryTypes" [value]="salaryType">
            {{salaryType}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="outline">
        <mat-label>Dienstverband</mat-label>
        <select matNativeControl formControlName="employment">
          <option *ngFor="let employmentType of employmentTypes" [value]="employmentType">
            {{employmentType}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="outline">
        <mat-label>Uren</mat-label>
        <mat-select formControlName="hours" multiple>
          <mat-option *ngFor="let hourType of hourTypes" [value]="hourType">
            {{hourType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="update-button">
        <button mat-raised-button color="primary" (click)="toggleFilterMenu()">
          <span>Update filter</span>
          <icon name="done"></icon>
        </button>
      </div>
    </div>
  </div>

  <div class="search-vacancy">
    <div class="top-container">
    <div class="search-container">
      <div class="search-text">Voor professionals</div>
      <div class="search-box">
        <div class="search-window">
          <div class="item top">
            <div class="item-header">Zoekterm</div>
            <mat-form-field class="profession" appearance="fill">
              <mat-label>Functie</mat-label>
              <input type="text"
                matInput
                formControlName="profession"
                [matAutocomplete]="auto1">
              <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let searchItem of searchKeywordAutosuggest" [value]="searchItem">
                  {{searchItem}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="item left">
            <div class="item-header">Locatie</div>
            <mat-form-field class="location" appearance="fill">
              <mat-label>Stad of provincie</mat-label>
              <input type="text"
                     matInput
                     formControlName="location"
                     [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of locationAutosuggest" [value]="item">
                  {{item}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="item bottom">
            <div class="item-header"></div>
            <button mat-raised-button color="primary" class="no-shadow" (click)="search()">
              <span>Zoeken</span>
              <icon name="search"></icon>
            </button>
          </div>
        </div>
        <div class="history">
          <div class="history-text" *ngIf="searchHistory.length > 0">Zoekterm geschiedenis:</div>
          <div class="keyword" *ngFor="let word of searchHistory.reverse(); let i = index">
            <span class="word" (click)="setProfession(word)">{{ word }}</span>
            <span *ngIf="showDivider(i)" class="divider"></span>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>

  <div *ngIf="isMobile" class="filter-mobile">
    <div class="filter-header">
      <div class="filter-text">Filters</div>
      <div class="clear-filter-button">
        <button mat-raised-button color="primary" class="no-shadow" (click)="clearAll()">
          <span>Alles wissen</span>
          <icon name="close"></icon>
        </button>
      </div>
    </div>
    <div class="filter-mobile-options">
      <div class="filter-mobile-settings" [ngClass]="{'toggled': !isFilterMenuHidden}" (click)="toggleFilterMenu()">
        <icon name="tune"></icon>
      </div>
      <mat-form-field class="filter-option" appearance="fill">
        <mat-label>Datum geplaatst</mat-label>
        <select matNativeControl formControlName="datePosted">
          <option *ngFor="let datePostedOption of dates" [value]="datePostedOption">
            {{datePostedOption}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="fill">
        <mat-label>Functiegebieden</mat-label>
        <mat-select formControlName="industries" multiple>
          <mat-option *ngFor="let industryOption of industryOptions" [value]="industryOption">
            {{industryOption}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="fill">
        <mat-label>Opleidingsniveau</mat-label>
        <select matNativeControl formControlName="education">
          <option *ngFor="let educationalLevel of educationalLevels" [value]="educationalLevel">
            {{educationalLevel}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="fill">
        <mat-label>Minimum salaris</mat-label>
        <select matNativeControl formControlName="salary">
          <option *ngFor="let salaryType of salaryTypes" [value]="salaryType">
            {{salaryType}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="fill">
        <mat-label>Dienstverband</mat-label>
        <select matNativeControl formControlName="employment">
          <option *ngFor="let employmentType of employmentTypes" [value]="employmentType">
            {{employmentType}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field class="filter-option" appearance="fill">
        <mat-label>Uren</mat-label>
        <mat-select formControlName="hours" multiple>
          <mat-option *ngFor="let hourType of hourTypes" [value]="hourType">
            {{hourType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div #filterRef *ngIf="!isMobile" class="container">
    <div class="filter">
      <div class="filter-header">
        <div class="filter-text">Filters</div>
        <div class="clear-filter-button">
          <button mat-raised-button color="primary" class="no-shadow" (click)="clearAll()">
            <span>Alles wissen</span>
            <icon name="close"></icon>
          </button>
        </div>
      </div>
      <div class="filter-options">
        <mat-form-field class="filter-option" appearance="outline">
          <mat-label>Datum geplaatst</mat-label>
          <mat-select formControlName="datePosted">
            <mat-option *ngFor="let datePostedOption of dates" [value]="datePostedOption">
              {{datePostedOption}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="filter-option" appearance="outline">
          <mat-label>Functiegebieden</mat-label>
          <mat-select formControlName="industries" multiple>
            <mat-option *ngFor="let industryOption of industryOptions" [value]="industryOption">
              {{industryOption}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="filter-option" appearance="outline">
          <mat-label>Opleidingsniveau</mat-label>
          <mat-select formControlName="education">
            <mat-option *ngFor="let educationalLevel of educationalLevels" [value]="educationalLevel">
              {{educationalLevel}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="filter-option" appearance="outline">
          <mat-label>Minimum salaris</mat-label>
          <mat-select formControlName="salary">
            <mat-option *ngFor="let salaryType of salaryTypes" [value]="salaryType">
              {{salaryType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="filter-option" appearance="outline">
          <mat-label>Dienstverband</mat-label>
          <mat-select formControlName="employment">
            <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType">
              {{employmentType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Uren</mat-label>
          <mat-select formControlName="hours" multiple>
            <mat-option *ngFor="let hourType of hourTypes" [value]="hourType">
              {{hourType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="vacancies" *ngIf="isLoadingJobs">
      <div class="vacancy" *ngFor="let i of pageSizeNgFor">
        <div class="job-description-header-skeleton skeleton-box"></div>
        <div class="margin-skeleton"></div>
        <div class="vacancy-filter-container">
          <div class="vacancy-filter-left">
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
          </div>
          <div class="vacancy-filter-right">
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="vacancy-description">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 60%;"></div>
          <div class="margin-small-skeleton"></div>
          <div class="vacancy-button">
            <div class="vacancy-apply-button-skeleton skeleton-box"></div>
          </div>
        </div>
        <div class="vacancy-time">
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 20%;"></div>
        </div>
      </div>
    </div>

    <div class="vacancies" *ngIf="!isLoadingJobs">
      <div #vacanciesRef class="vacancy" *ngFor="let vacancy of displayedJobsCatsOne">
        <div class="vacancy-header">{{vacancy.title}}</div>
        <div class="vacancy-filter-container">
          <div class="vacancy-filter-left">
            <div class="vacancy-filter-row">
              <icon name="location_on"></icon>
              <div class="vacancy-filter-row-container">
                <span>Locatie</span>
                <span class="vacancy-type">{{vacancy.location.city}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="business"></icon>
              <div class="vacancy-filter-row-container">
                <span>Functiegebieden</span>
                <div class="vacancy-type">{{mapIndustries(vacancy)}}</div>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="school"></icon>
              <div class="vacancy-filter-row-container">
                <span>Opleidingsniveau</span>
                <span class="vacancy-type">{{educationOrNA(vacancy)}}</span>
              </div>
            </div>
          </div>
          <div class="vacancy-filter-right">
            <div class="vacancy-filter-row">
              <icon name="euro"></icon>
              <div class="vacancy-filter-row-container">
                <span>Salaris</span>
                <span class="vacancy-type">{{parseSalary(vacancy.salary)}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="schedule"></icon>
              <div class="vacancy-filter-row-container">
                <span>Dienstverband</span>
                <span class="vacancy-type">{{employmentOrNA(vacancy)}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="hourglass"></icon>
              <div class="vacancy-filter-row-container">
                <span>Uren</span>
                <span class="vacancy-type">{{parseHours(vacancy)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vacancy-description">
          <span *ngIf="vacancy.shortDescription" class="vacancy-text" [innerHTML]="vacancy.shortDescription"></span>
          <div class="vacancy-button">
            <button mat-raised-button color="primary" class="google-maps no-shadow" (click)="viewJob($event, vacancy)">
              <span>Bekijk vacature</span>
              <icon name="navigate_next"></icon>
            </button>
          </div>
        </div>
        <div class="vacancy-time">Geplaatst: {{dateToDatePosted(vacancy.publicationDate)}}</div>
        <!-- <div class="vacancy-time">Laatst geupdate: {{dateToDatePosted(vacancy.dateModified)}}</div> -->
      </div>
      <div *ngIf="noResultsFound">
          <div class="no-results">
            <div class="no-results-header">
              <icon name="sentiment_dissatisfied"></icon>
              <div>Sorry, we hebben geen relevante resultaten kunnen vinden.</div>
            </div>
            <div class="no-results-button">
              <button mat-raised-button color="primary" class="google-maps no-shadow" (click)="clearAll()">
                <span>Verwijder zoekopdracht</span>
                <icon name="close"></icon>
              </button>
            </div>
        </div>
      </div>
      <div #paginatorRef *ngIf="!noResultsFound" class="paginator scroll-fade">
        <mat-paginator 
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageChange($event)"
        aria-label="Select page">
      </mat-paginator>
      </div>
      <div #jobNotFoundRef class="job-not-found scroll-fade">
        <div class="job-not-found-title">Open sollicitatie</div>
        <div class="job-not-found-text">Niet direct kunnen vinden waar je naar zocht? Doe een open sollicitatie en wij zetten ons netwerk in om voor jou de best passende baan te vinden!</div>
        <div class="vacancy-button">
          <button mat-raised-button color="primary" class="no-shadow" (click)="apply($event)">
            <span>Open sollicitatie</span>
            <icon name="navigate_next"></icon>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>