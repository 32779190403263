<div class="employers">
</div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text">Voor opdrachtgevers</div>
  </div>
</div>
<div class="container-1">
  <div #containerRef class="container-2 scroll-fade">
    <div class="left">
      <div class="title">Onze werkwijze</div>
      <div class="information">
        <div>
          <div class="paragraph-1">Na de analyse van jouw organisatie en de openstaande vacature zullen wij beginnen met het zoeken naar de best passende professionals in ons netwerk. Wij doen dit op basis van een samen opgesteld searchprofiel met de door jou gewenste kwalificaties.</div>
          <div class="paragraph-1">Na onze eerste intakegesprekken met kandidaten, ontvang jij de profielen van de best passende IT Professional op jouw vacature. Vervolgens plannen we voor jou de afspraak in!</div>
          <div class="title-2">Onderscheidend recruitment</div>
          <div class="paragraph-1">Blauwtand is de bedenker van het circulair recruitment project, dat in 2021 is geïntroduceerd in de markt. Wij onderscheiden ons door gebruik te maken van meerdere (ZZP) recruiters & HR professionals in een samenwerkingsverband. Zij geven kandidaten die niet bij hun organisatie of opdrachtgever passen een tweede kans binnen het circulair recruitment netwerk. Voor een kandidaat betekent dit een extra kans op een beter passende baan. Voor u als opdrachtgever betekent het snel beschikking hebben over een pool van actief zoekende IT specialisten.</div>
        </div>
      </div>
      <!-- <img class="right-image" src="./assets/IMG_0963 - Groep tafel.JPEG" alt="Voor opdrachtgevers"> -->
      <div class="employers-image"></div>
    </div>
  </div>

  <div #contactRef class="contact scroll-fade">
    <div class="c-header">Interesse?</div>
    <div class="c-text">Ben jij benieuwd wat Blauwtand voor jouw organisatie kan betekenen? Laten we dan snel kennis maken. Mail naar ons via <span class="link-container"><icon name="email"></icon><a class="link" (click)="email('info@blauwtand.com')">info@blauwtand.com</a></span> of bel gerust met één van onze collega’s via het volgende telefoonnummer: <span class="link-container"><icon name="call"></icon><a class="link" (click)="phoneCall('31798080328')">{{ phoneNumber }}</a></span>.</div>
    <button mat-raised-button color="primary" class="google-maps no-shadow" (click)="navigateTo('contact')">
      <span>Contact</span>
      <icon name="navigate_next"></icon>
    </button>
  </div>
</div>


