import { environment } from "client/environments/environment";
import { JobCatsOne, Salary } from "./vacancy-data.component";

export class Utils {
  static phoneNumber = "+31 798 080 320"
  static whatsAppNumber = "+31 798 080 328"

  public static getApiUrl() {
    return environment.apiUrl;
  }

  public static async timeout(ms: number = 50) {
    await new Promise(res => setTimeout(res, ms))
  }

  public static mapIndustries(job: JobCatsOne) {
    const result = job.industries.reduce((prev, current, index) => {
        if(index === 0) {
          return current
        }
        const result = `${prev}, ${current}`
        return result
    }, '')
    return result.length > 0 ? result : "N/A"
  }

  public static redirectTo(name: string): void {
    let url = ''
    switch(name) {
      case "facebook": url = "https://facebook.com/BlauwtandIT"; break
      case "twitter": url = "http://twitter.com"; break
      case "linkedin": url = "https://www.linkedin.com/company/blauwtand/"; break
      case "instagram": url = "https://instagram.com/blauwtand_it"; break
    }
    window.open(url)
  }

  public static dateToDatePosted(datePosted?: Date) {
    if(datePosted) {
      return new Date(datePosted).toLocaleDateString("nl-NL", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
    return "N/A"
  }

  public static parseJobDescription(description?: string) {
    if(description) {
      return description.replace(new RegExp("<h1.+<\/h1>"), '')
    }
    return ""
  }

  public static phoneCall(number: string): void {
    window.location.href = `tel:+${number}`;
  }

  public static email(email: string): void {
    window.open(`mailto:${email}`);
  }

  public static whatsapp(number: string): void {
    window.location.href = `https://api.whatsapp.com/send?phone=${number}`
  }

  public static parseJob(data: { job: any, recruiter?: any}): JobCatsOne {
    const job = data?.job
    const recruiter = data?.recruiter

    let educationObject = (job._embedded.custom_fields as any[]).find(i => i.id === customFields.education)
    let employmentObject = (job._embedded.custom_fields as any[]).find(i => i.id === customFields.employment)
    let hoursObject = (job._embedded.custom_fields as any[]).find(i => i.id === customFields.hours)
    let industriesObject = (job._embedded.custom_fields as any[]).find(i => i.id === customFields.industries)
    let education = ''
    let employment = ''
    let hours = new Array<string>()
    let industries = new Array<string>()
    
    if(educationObject) {
      education = (educationObject._embedded.definition.field.selections as any[]).find(i => i.id === educationObject.value)?.label
    }

    if(employmentObject) {
      employment = (employmentObject._embedded.definition.field.selections as any[]).find(i => i.id === employmentObject.value)?.label
    }

    if(hoursObject) {
      (hoursObject.value as any[]).forEach(val => {
        hours.push((hoursObject._embedded.definition.field.selections as any[]).find(i => i.id === val)?.label)
      })
    }

    if(industriesObject) {
      (industriesObject.value as any[]).forEach(val => {
        industries.push((industriesObject._embedded.definition.field.selections as any[]).find(i => i.id === val)?.label)
      })
    }

    const salaryString: string = job.salary.trim()
    const salary = new Salary()
    if(salaryString.includes('-')) {
      salary.min = Number(salaryString.substring(0, salaryString.indexOf("-")).trim())
      salary.max = Number(salaryString.substring(salaryString.indexOf("-") + 1, salaryString.length))
    } else {
      salary.static = Number(salaryString.trim())
    }

    const result: JobCatsOne = {
      industry: job.category_name === "none" ? "Onbekend" : job.category_name,
      countryCode: job.country_code,
      dateCreated: job.date_created,
      dateModified: job.date_modified,
      description: job.description,
      id: job.id,
      location: {
        city: job.location?.city ?? '',
        postalCode: job.location?.postal_code ?? '',
        state: job.location?.state ?? ''
      },
      title: job.title,
      salary: salary,
      education: education,
      shortDescription: (job._embedded.custom_fields as any[]).find(i => i.id === customFields.shortDescription)?.value ?? '',
      employment: employment,
      hours: hours,
      industries: industries,
      publicationDate: (job._embedded.custom_fields as any[]).find(i => i.id === customFields.publicationDate)?.value ?? new Date(2022, 1, 1),
      recruiter: {
        firstName: recruiter?.first_name ?? '',
        lastName: recruiter?.last_name ?? '',
        userName: recruiter?.username ?? '',
        phoneNumber: recruiter?._embedded?.phones?.[0].number ?? ''
      }
    }
    return result
  }

  public static parseSalary(salary?: Salary) {
    if(!salary) {
      return ''
    }
    if(salary.static) {
      return String(salary.static)
    } else {
      return `${salary.min} - ${salary.max}`
    }
  }

  public static parseHours(job: JobCatsOne) {
    let str = ''
    job.hours.forEach((i, index) => {
      str += i
      if(index + 1 < job.hours.length) {
        str += ", "
      }
    })
    return str.length > 0 ? str : "N/A"
  }

  public static salaryToNumber(salary: string) {
    switch(salary) {
      case "€2000+": return 2000
      case "€3000+": return 3000
      case "€4000+": return 4000
      case "€5000+": return 5000
      case "€6000+": return 6000
      case "€7000+": return 7000
    }
    return 0
  }

  public static saveSearchHistory(currentProfession: string) {
    if(localStorage.getItem("history")) {
      let history = JSON.parse(localStorage.getItem("history") ?? '')

      if(history) {
        const profession = history.profession as string[]
        if(currentProfession && !profession.includes(currentProfession)) {
          history.profession.push(currentProfession)
          if(history.profession.length > 3) {
            history.profession = history.profession.slice(1, 4)
          }
          localStorage.setItem("history", JSON.stringify({ profession: history.profession }))
        }
      }
    } else {
      localStorage.setItem("history", JSON.stringify({ profession: [currentProfession]}))
    }
  }
}

export const hoursOptions = {
  [40]: 234370,
  [36]: 234373,
  [32]: 234376,
}

export const customFields = {
  salaryMin: 40624,
  salaryMax: 40627,
  shortDescription: 40630,
  education: 40633,
  employment: 40639,
  hours: 40645,
  industries: 40666,
  publicationDate: 40681,
}

export const industries = [
  // "Software & informatietechnologie",
  // "Sales & retail",
  // "Media, design & cultuur",
  // "Marketing, communicatie & reclame",
  // "Onderzoek & wetenschap",
  // "Onderwijs & instructie",
  // "Zorg & welzijn",
  // "Food & beverage",
  "Information Technology"
]

export const educationalLevels = [
  "MBO",
  "HBO",
  "WO",
]

export const employmentTypes = [
  "Vast dienstverband",
  "Tijdelijk/Freelance/ZZP",
]

export const industryOptions = [
  "Development",
  "Applicatiebeheer",
  "ICT Beheer",
  "Business & IT",
  "ICT support",
  "Testen",
  "Data",
  "IT management",
]

export const salaryTypes = [
  "€2000+",
  "€3000+",
  "€4000+",
  "€5000+",
  "€6000+",
  "€7000+",
]

export const hourTypes = [
  '40',
  '36',
  '32',
]

export const provinces = [
  "Drenthe",
  "Flevoland",
  "Friesland",
  "Gelderland",
  "Groningen",
  "Limburg",
  "Noord-Brabant",
  "Noord-Holland",
  "Overijssel",
  "Utrecht",
  "Zeeland",
  "Zuid-Holland",
]

export const searchKeywords = [
  "Software Engineer",
  "Software Developer",
  "Field Engineer",
  ".NET Developer",
  "Functioneel Applicatiebeheerder",
  "Hardware Engineer",
  "Embedded Developer",
  "Developer",
  "Programmeur",
  "Cloud Engineer",
  "Support Specialist",
  "System Analyst",
  "Web Developer",
  "Full Stack Developer",
  "Frontend Engineer",
  "Backend Engineer",
  "Project Manager",
  "Scrum Master",
  "Product Owner",
  "Network Engineer",
  "Database Administrator",
  "Security Analyst",
  "System Administrator",
  "Programmer",
  "IT technician",
  "User Experience Designer",
  "Computer Scientist",
  "IT Security Specialist",
  "Data Scientist",
  "IT Director",
  "Web Administrator",
  "IT Coordinator",
  "Data Quality Manager",
  "Cloud System Engineer",
  "Applications Engineer",
  "Systeem Beheerder",
]

export const cities = [
  "Den Haag",
  "Amsterdam",
  "Utrecht",
  "Rotterdam",
  "Eindhoven",
  "Almere",
  "Groningen",
  "Breda",
  "Apeldoorn",
  "Haarlem",
  "Zaanstad",
  "Arnhem",
  "’s-Hertogenbosch",
  "Leeuwarden",
  "Maastricht",
  "Zwolle",
  "Alphen aan den Rijn",
  "Emmen",
  "Delft",
  "Deventer",
  "Hilversum",
  "Heerlen",
  "Purmerend",
  "Lelystad",
  "Roosendaal",
  "Spijkenisse",
  "Ede",
  "Gouda",
  "Zaandam",
  "Bergen op Zoom",
  "Capelle aan den IJssel",
  "Veenendaal",
  "Katwijk",
  "Zeist",
  "Zoetermeer",
  "Nieuwegein",
  "Assen",
  "Hardenberg",
  "Barneveld",
  "Roermond",
  "Heerhugowaard",
  "Oosterhout",
  "Den Helder",
  "Hoogeveen",
  "Kampen",
  "Woerden",
  "Houten",
  "Sittard",
  "IJmuiden",
  "Middelburg",
  "Harderwijk",
  "Zutphen",
  "Ridderkerk",
  "Kerkrade",
  "Veldhoven",
  "Medemblik",
  "Zwijndrecht",
  "Vlissingen",
  "Rheden",
  "Etten-Leur",
  "Zevenaar",
  "Venray",
  "Noordwijk",
  "Tiel",
  "Uden",
  "Huizen",
  "Beverwijk",
  "Wijchen",
  "Dronten",
  "Hellevoetsluis",
  "Maarssen",
  "Leidschendam",
  "Heemskerk",
  "Veghel",
  "Goes",
  "Venlo",
  "Landgraaf",
  "Teijlingen",
  "Geleen",
  "Hellendoorn",
  "Castricum",
  "Gorinchem",
  "IJsselstein",
  "Sneek",
  "Tynaarlo",
  "Maassluis",
  "Bussum",
  "Deurne",
  "Oldenzaal",
  "Aalsmeer",
  "Rosmalen",
  "Lonneker",
  "Hendrik-Ido-Ambacht",
  "Valkenswaard",
  "Boxtel",
  "Leusden",
  "Bergen",
  "Heesch",
  "Oosterend",
  "Krimpen aan den IJssel",
  "Diemen",
  "Boxmeer",
  "Sint-Michielsgestel",
  "Winterswijk",
  "Culemborg",
  "Heerenveen",
  "Geldrop",
  "Brunssum",
  "Langedijk",
  "Zaltbommel",
  "Heemstede",
  "Drimmelen",
  "Leiderdorp",
  "Aalten",
  "Werkendam",
  "Ermelo",
  "Rijssen",
  "Wassenaar",
  "Oisterwijk",
  "Dongen",
  "Beuningen",
  "Veenoord",
  "Duiven",
  "Sliedrecht",
  "Stein",
  "Cuijk",
  "Oegstgeest",
  "Oud-Beijerland",
  "Putten",
  "Goirle",
  "Schijndel",
  "Oldebroek",
  "Heiloo",
  "Loon op Zand",
  "Borne",
  "Steenwijk",
  "Hoensbroek",
  "Losser",
  "Beek en Donk",
  "Zeewolde",
  "Oud-Zuilen",
  "Woensdrecht",
  "Nieuwerkerk aan den IJssel",
  "Zundert",
  "Geertruidenberg",
  "Tubbergen",
  "Leerdam",
  "Winschoten",
  "Urk",
  "Poortugaal",
  "Cranendonck",
  "Bladel",
  "Rhenen",
  "Wisch",
  "Vriezenveen",
  "Helden",
  "Tegelen",
  "Someren",
  "Echt",
  "Lichtenvoorde",
  "Eersel",
  "Meerssen",
  "Zuidhorn",
  "Groesbeek",
  "Edam",
  "Druten",
  "Oirschot",
  "Bergeijk",
  "Drunen",
  "Bemmel",
  "Elst",
  "Hardinxveld-Giessendam",
  "Middelharnis",
  "Bodegraven",
  "Sint-Oedenrode",
  "Ommen",
  "Naarden",
  "Waalre",
  "Gennep",
  "Zandvoort",
  "Staphorst",
  "Son en Breugel",
  "Asten",
  "Kralingse Veer",
  "Didam",
  "Heumen",
  "Eibergen",
  "Harenkarspel",
  "Beek",
  "Vlijmen",
  "Zevenbergen",
  "Harlingen",
  "Huissen",
  "Hilvarenbeek",
  "Bunnik",
  "Den Ham",
  "Boskoop",
  "Westervoort",
  "IJsselmuiden",
  "Sassenheim",
  "Rijnsburg",
  "Rozenburg",
  "Breukelen",
  "Lopik",
  "Scheemda",
  "Gorssel",
  "Haaren",
  "Beilen",
  "Maasbracht",
  "Raamsdonk",
  "Winsum",
  "Uitgeest",
  "Beesel",
  "Valburg",
  "Margraten",
  "Reeuwijk",
  "Maasbree",
  "Bergschenhoek",
  "Woudenberg",
  "Bergen",
  "Odoorn",
  "Mijdrecht",
  "Schinnen",
  "Borger",
  "Kapelle",
  "Franeker",
  "Halsteren",
  "Wieringerwerf",
  "Grave",
  "Neerijnen",
  "Dokkum",
  "Goor",
  "Axel",
  "Hattem",
  "Heythuysen",
  "Oosterbroek",
  "Budel",
  "Schoonhoven",
  "Opmeer",
  "Ubach over Worms",
  "Sint Anthonis",
  "Eijsden",
  "Wormer",
  "Hazerswoude-Dorp",
  "Zierikzee",
  "Ulrum",
  "Zelhem",
  "Blaricum",
  "Laren",
  "Doesburg",
  "Doornspijk",
  "Schipluiden",
  "Neede",
  "Diepenveen",
  "Boekel",
  "Borculo",
  "Monnickendam",
  "Bleiswijk",
  "Prinsenbeek",
  "Doorn",
  "Vaals",
  "Haelen",
  "Nieuwenhagen",
  "Bolsward",
  "Scherpenzeel",
  "Vlist",
  "Oostzaan",
  "Liesveld",
  "Vries",
  "Heteren",
  "Wamel",
  "Nieuw-Lekkerland",
  "Hoek van Holland",
  "Ubbergen",
  "Olst",
  "Weerselo",
  "Smilde",
  "Nieuwleusen",
  "Ter Aar",
  "Groenlo",
  "Eemnes",
  "Heer",
  "Holten",
  "Abcoude",
  "Wervershoof",
  "Oudover",
  "Swalmen",
  "Dinxperlo",
  "Loenen",
  "Vorden",
  "Hoevelaken",
  "Hengelo",
  "Zoeterwoude",
  "Dirksland",
  "Assendelft",
  "Steenwijkerwold",
  "Heel",
  "Hoeven",
  "Oude Pekela",
  "Ouderkerk aan de Amstel",
  "Elsloo",
  "Ruurlo",
  "Oostvoorne",
  "Reusel",
  "Wognum",
  "Hasselt",
  "Moordrecht",
  "Heino",
  "Harmelen",
  "Venhuizen",
  "Wittem",
  "Numansdorp",
  "Schoonebeek",
  "Anna Paulowna",
  "Ten Boer",
  "Lekkerkerk",
  "Gendt",
  "Markelo",
  "Norg",
  "Sevenum",
  "Amerongen",
  "Sappemeer",
  "Rhoon",
  "Ruinen",
  "Grubbenvorst",
  "Lienden",
  "Baarle-Nassau",
  "Maasland",
  "Teteringen",
  "Lith",
  "Oudorp",
  "Klundert",
  "Schoorl",
  "Bellingwolde",
  "Gramsbergen",
  "Obdam",
  "Andijk",
  "Erp",
  "Zevenhuizen",
  "Kerkwijk",
  "Groot-Valkenisse",
  "Rolde",
  "Dussen",
  "Krimpen aan de Lek",
  "IJsselmonde",
  "Hooge Zwaluwe",
  "Maarn",
  "Eygelshoven",
  "Millingen aan de Rijn",
  "Gieten",
  "Maurik",
  "Havelte",
  "Bennebroek",
  "Moergestel",
  "Meijel",
  "Zeeland",
  "Berghem",
  "Puttershoek",
  "Rijsbergen",
  "Nieuwe Pekela",
  "Dalen",
  "Westenschouwen",
  "Ouderkerk aan den IJssel",
  "Wilnis",
  "Limbricht",
  "Belfeld",
  "Bovenkarspel",
  "Schermerhorn",
  "Hoogkerk",
  "Renswoude",
  "Nederhorst den Berg",
  "Bathmen",
  "Warmond",
  "Stompwijk",
  "Velden",
  "Sint Pancras",
  "Arnemuiden",
  "Wanroij",
  "Peize",
  "Steenderen",
  "Stramproy",
  "Uithuizen",
  "Urmond",
  "Waspik",
  "Uithuizermeeden",
  "Schinveld",
  "Bunde",
  "Muntendam",
  "Midwolda",
  "Oirsbeek",
  "Leimuiden",
  "Termunten",
  "Zwartsluis",
  "Koudekerk aan den Rijn",
  "Linschoten",
  "Helvoirt",
  "Hulsberg",
  "Giessenburg",
  "Bierum",
  "Ootmarsum",
  "Dodewaard",
  "Roggel",
  "Haastrecht",
  "Stolwijk",
  "Driel",
  "Driebruggen",
  "Berkhout",
  "Ammerzoden",
  "Den Dungen",
  "Ewijk",
  "Gasselte",
  "Klimmen",
  "Kessel",
  "Mijnsheerenland",
  "Warmenhuizen",
  "Vleuten",
  "Voorhout",
  "Wedde",
  "Leende",
  "Leens",
  "Hedel",
  "Hoogkarspel",
  "Diessen",
  "Kortgene",
  "Cadier en Keer",
  "Delden",
  "Clinge",
  "Linne",
  "Wissekerke",
  "Barsingerhorn",
  "Putte",
  "Spaubeek",
  "Sommelsdijk",
  "Klein Mariekerke",
  "Klaaswaal",
  "Westerblokker",
  "Benthuizen",
  "Oud-Vroenhoven",
  "Zaamslag",
  "Wijk en Aalburg",
  "Chaam",
  "Beesd",
  "Neer",
  "Veur",
  "Vledder",
  "Diever",
  "Sint Odiliënberg",
  "Muiden",
  "Herten",
  "Zuid-Beijerland",
  "Moerkapelle",
  "Koudekerke",
  "Willemstad",
  "Amstenrade",
  "Meerlo",
  "Hoogwoud",
  "Ooltgensplaat",
  "Dreumel",
  "Overasselt",
  "Rossum",
  "Oude-Tonge",
  "Maasdam",
  "Kortenhoef",
  "Amby",
  "Dinther",
  "Meerkerk",
  "IJzendijke",
  "Eenrum",
  "Oudshoorn",
  "Geulle",
  "Deil",
  "Haps",
  "Kockengen",
  "Koedijk",
  "Zwammerdam",
  "Nieuw-Beijerland",
  "Luyksgestel",
  "Zevenhoven",
  "Dieren",
  "Grathem",
  "Nijeveen",
  "Standdaarbuiten",
  "Zweeloo",
  "Middelstum",
  "Ameide",
  "Buurmalsen",
  "Koewacht",
  "Broek in Waterland",
  "Hallum",
  "Berlicum",
  "Gouderak",
  "Buren",
  "Asperen",
  "Callantsoog",
  "Grevenbicht",
  "Giethoorn",
  "Udenhout",
  "Thorn",
  "Oldehove",
  "Nieuwendam",
  "Vlodrop",
  "Capelle",
  "Varsseveld",
  "Wemeldinge",
  "Groede",
  "Nieuw-Helvoet",
  "Maarsseveen",
  "Bergharen",
  "Heeswijk",
  "Baexem",
  "Stedum",
  "Cothen",
  "Streefkerk",
  "Loppersum",
  "Stoutenburg",
  "Herwijnen",
  "Broek op Langedijk",
  "Geervliet",
  "Avenhorn",
  "Ursem",
  "Sambeek",
  "Oldekerk",
  "Nieuwolda",
  "Kantens",
  "Pannerden",
  "Spanbroek",
  "Nieuw-Vossemeer",
  "Winkel",
  "Nieuwe-Tonge",
  "Ezinge",
  "Sint Maarten",
  "Wadenoijen",
  "Huijbergen",
  "Westmaas",
  "Geesteren",
  "Tricht",
  "Waardenburg",
  "Vaassen",
  "Nederlangbroek",
  "Nieuwe-Niedorp",
  "Melissant",
  "Ransdorp",
  "Riethoven",
  "De Rijp",
  "Meeden",
  "Noord-Scharwoude",
  "Op-Andel",
  "Schalkwijk",
  "Oud-Alblas",
  "Usquert",
  "Neeritter",
  "Stellendam",
  "Stavenisse",
  "Esch",
  "Den Bommel",
  "Zegveld",
  "Hegelsom",
  "Broekhuizen",
  "Everdingen",
  "Merkelbeek",
  "Oudkarspel",
  "Vierpolders",
  "Nieuwenhoorn",
  "Mheer",
  "Stoppeldijkveer",
  "Zuidland",
  "Buiksloot",
  "Herpen",
  "Grootebroek",
  "Wanneperveen",
  "Westwoud",
  "Zuid-Scharwoude",
  "Beltrum",
  "Goudswaard",
  "Besoijen",
  "Baardwijk",
  "Rozendaal",
  "Harfsen",
  "Jaarsveld",
  "Blokzijl",
  "Graft",
  "Veere",
  "Terborg",
  "Engelen",
  "Borssele",
  "Oude-Niedorp",
  "Stevensweert",
  "Werkhoven",
  "Schin op Geul",
  "Veen",
  "Boven-Leeuwen",
  "Colijnsplaat",
  "Aagtekerke",
  "Domburg",
  "Noordeloos",
  "Oosterbeek",
  "Oudenhoorn",
  "Bredevoort",
  "Ochten",
  "Gronsveld",
  "Opperdoes",
  "Abbekerk",
  "Sint Geertruid",
  "Wieringerwaard",
  "Nieuwkuijk",
  "Hoornaar",
  "Nijbroek",
  "Berkenwoude",
  "Schoonrewoerd",
  "Midwoud",
  "Velp",
  "Horssen",
  "Wanssum",
  "Piershil",
  "Hoogblokland",
  "Hedikhuizen",
  "Herveld",
  "Vreeland",
  "Zwartewaal",
  "Varik",
  "Garderen",
  "Schimmert",
  "Brouwershaven",
  "Lunteren",
  "Stad aan ’t Haringvliet",
  "Wijdewormer",
  "Vinkeveen",
  "Oudenrijn",
  "Rijswijk",
  "Zes Gehuchten",
  "Rijnsaterwoude",
  "Netterden",
  "Etten",
  "Eede",
  "Kattendijke",
  "Posterholt",
  "Leerbroek",
  "Oosthuizen",
  "Rijsenburg",
  "Nigtevecht",
  "Hoogvliet",
  "Wilp",
  "Kedichem",
  "Nieuwpoort",
  "Giessen",
  "Zuilichem",
  "Ophemert",
  "Voorthuizen",
  "Groot-Ammers",
  "Staveren",
  "Bleskensgraaf",
  "Hooge Mierde",
  "Heerewaarden",
  "Genderen",
  "Noorbeek",
  "Polsbroek",
  "Brandwijk",
  "Hekelingen",
  "Westkapelle",
  "Nieuwerkerke",
  "Schelluinen",
  "Zuidzande",
  "Odijk",
  "Oud-Heusden",
  "Ankeveen",
  "Willeskop",
  "Herkingen",
  "Zonnemaire",
  "Nederhemert",
  "Lent",
  "Twello",
  "Kerkbuurt",
  "Barwoutswaarder",
  "Goudriaan",
  "Oranjestad",
  "Waarder",
  "Doorwerth",
  "Reek",
  "Jisp",
  "Twisk",
  "Zuiddorpe",
  "Groote Lindt",
  "Schore",
  "Molenaarsgraaf",
  "Oterleek",
  "Lierop",
  "Dalem",
  "Schiermonnikoog",
  "Veessen",
  "Middelie",
  "Grijpskerke",
  "Rilland",
  "Ossenisse",
  "Broeksittard",
  "Sloten",
  "Achttienhoven",
  "Ottoland",
  "Bennekom",
  "Noordgouwe",
  "Veldhuizen",
  "Hall",
  "Hekendorp",
  "Sluipwijk",
  "Snelrewaard",
  "Schellinkhout",
  "Oudelande",
  "Rietveld",
  "Ellewoutsdijk",
  "Buggenum",
  "Jabeek",
  "Meliskerke",
  "Lithoijen",
  "Grafhorst",
  "Hoenkoop",
  "Sint Kruis",
  "Winssen",
  "Batenburg",
  "Loenersloot",
  "Vlierden",
  "Opijnen",
  "Waterlandkerkje",
  "Slenaken",
  "Wijngaarden",
  "Gassel",
  "Maasbommel",
  "Borgharen",
  "Spaarnwoude",
  "Heukelum",
  "Dommelen",
  "Otterlo",
  "Bruchem",
  "Hurwenen",
  "Elkerzee",
  "Zuidveen",
  "IJzendoorn",
  "Hernen",
  "Herpt",
  "Verwolde",
  "Ellemeet",
  "Haarzuilens",
  "Montfort",
  "Blankenham",
  "Bommenede",
  "Vrijhoeve-Capelle",
  "Oost-Souburg",
  "Warder",
  "Tempel",
  "Waverveen",
  "Balgoij",
  "Stein",
  "The Bottom",
  "Deursen",
  "Oost-Knollendam",
  "Laagnieuwkoop",
  "Kwadijk",
  "Pieterburen",
  "Velp",
  "Wieldrecht",
  "Sint Anthoniepolder",
  "Sandelingen-Ambacht",
  "Oudhuizen",
  "Petten",
  "Spijk",
  "Puiflijk",
  "Drongelen",
  "Peursum",
  "Rijckholt",
  "Kleine-Lindt",
  "Rimburg",
  "Grosthuizen",
  "Nunhem",
  "Strucht",
  "Ittervoort",
  "Cillaarshoek",
  "Strijensas",
  "Mesch",
  "Hoogmade",
  "Haarlemmerliede",
  "Oud-Wulven",
  "Weurt",
  "Hoenzadriel",
  "Sterkenburg",
  "Zouteveen",
  "Katwoude",
  "Bokhoven",
  "Kats",
  "Darthuizen",
  "Stormpolder"
]

export const locationKeywords = provinces.concat(cities)