import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { SharedService } from './shared.service';
import { JobCatsOne } from './vacancy-data.component';
import { employmentTypes, Utils } from "./utils"
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss']
})
export class ViewJobComponent implements OnInit, AfterViewInit {
  @ViewChild("spinnerRef") spinnerRef: ElementRef
  @ViewChild("viewJobRef") viewJobRef: ElementRef
  @ViewChild("notAvailableRef") notAvailableRef: ElementRef
  @ViewChild("vacancyRef") vacancyRef: ElementRef
  @ViewChild("buttonsRef") buttonsRef: ElementRef
  @ViewChild("recruiterRef") recruiterRef: ElementRef
  @ViewChild("relatedJobsTitleRef") relatedJobsTitleRef: ElementRef
  @ViewChildren("relatedJobsContainerRef") relatedJobsContainerRef: QueryList<any>
  
  public job: JobCatsOne
  public relatedJobs: JobCatsOne[] = []
  public isLoadingJob = true
  public isLoadingRelatedJobs = true
  public isMobile = false
  public jsonLD: SafeHtml;
  public isPublished = true
  public time = 5
  public relatedVacanciesToggle = "publicationDate"
  public mediaQuery1400: MediaQueryList
  public dateToDatePosted = Utils.dateToDatePosted
  public parseJobDescription = Utils.parseJobDescription
  public phoneCall = Utils.phoneCall
  public email = Utils.email
  public parseSalary = Utils.parseSalary
  public parseHours = Utils.parseHours
  public mapIndustries = Utils.mapIndustries

  public constructor(
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private googleTagManagerService: GoogleTagManagerService,
    public sharedService: SharedService
  ) {}

  public relatedVacanctiesToggle(event: MatButtonToggleChange ) {
    this.relatedVacanciesToggle = event.value
    if (this.relatedVacanciesToggle === "publicationDate") {
      this.sortRelatedJobsByTime()
    } else {
      this.relatedJobs.sort((a, b) => a.title.localeCompare(b.title))
    }
  }

  private sortRelatedJobsByTime() {
    this.relatedJobs.sort((a, b) => new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime())
  }

  public ngOnInit(): void {
    this.mediaQuery1400 = window.matchMedia("(min-width: 1401px)")
    this.mediaQuery1400.addListener((_) => {
      this.updateRelatedJobContainerHeight()
    })
    window.addEventListener("resize", (_) => {
      this.updateRelatedJobContainerHeight()
    })
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — Vacature")
    this.jsonLD = this.defaultJsonLD
    if(!this.sharedService.selectedJobId) {
      this.activatedRoute.params.subscribe(params => {
        this.getJob(params?.["id"])
      })
    } else {
      this.getJob(this.sharedService.selectedJobId)
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    this.relatedJobsContainerRef.changes.subscribe(_ => {
      this.updateRelatedJobContainerHeight()
    })

    await Utils.timeout()
    if(window.innerWidth <= 767 ) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    this.scrollFade()
  }

  private updateRelatedJobContainerHeight() {
    const viewJobElement = this.vacancyRef.nativeElement as HTMLDivElement
    const viewJobElementRect = viewJobElement.getBoundingClientRect()
    if (!this.recruiterRef) { return }
    const recruiterElement = this.recruiterRef.nativeElement as HTMLDivElement
    const recruiterElementRect = recruiterElement.getBoundingClientRect()
    const relatedJobsTitleElement = this.relatedJobsTitleRef.nativeElement as HTMLDivElement
    const relatedJobsTitleElementRect = relatedJobsTitleElement.getBoundingClientRect()
    const relatedJobsElement = (this.relatedJobsContainerRef.toArray()?.[0] as ElementRef)?.nativeElement as HTMLDivElement
    if (this.mediaQuery1400.matches) {
      relatedJobsElement.style.maxHeight = `${viewJobElementRect.height - recruiterElementRect.height - relatedJobsTitleElementRect.height - 32 - 16}px`
    } else {
      relatedJobsElement.style.maxHeight = '500px'
    }

  }
 
  public get countdown(): number {
    return this.time <= 0 ? 0 : this.time
  }

  public getJob(id: number) {
    this.httpClient.get(`${Utils.getApiUrl()}/jobs/${id}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      const typedResponse = (response as { job: any, recruiter: any})
      if(Object.keys(typedResponse.job).length > 0) {
        this.job = Utils.parseJob(typedResponse)
        this.parseGoogleJob()
        this.httpClient.get(`${Utils.getApiUrl()}/relatedJobs/${id}`, {
          headers: new HttpHeaders({
            "Content-Type": "application/json"
          }),
          params: { title: this.job.title }
        }).subscribe(r => {
          const relatedJobs = (r as { relatedJobs: any[] })
          this.relatedJobs = relatedJobs?.relatedJobs ?? []
          this.sortRelatedJobsByTime()
          this.isLoadingRelatedJobs = false
        })
      }
      this.isLoadingJob = false
      this.isPublished = typedResponse.job?.is_published
      window.scrollTo(0, 0)

      if(!this.isPublished) {
        const notAvailable = this.notAvailableRef?.nativeElement as HTMLElement
        notAvailable.classList.remove("scroll-fade")
        setInterval(() => {
          this.time -= 1
          if(this.time <= 0) {
            this.navigateTo("vacancies")
          }
        }, 1000)
      }
    })
  }

  public get defaultJsonLD() {
    const obj = {
      "@context" : "https://schema.org/",
      "@type" : "JobPosting",
      "title" : "",
      "description" : "",
      "identifier": {
        "@type": "PropertyValue",
        "name": "Blauwtand",
        "value": ""
      },
      "datePosted" : "",
      "applicantLocationRequirements": {
        "@type": "Country",
        "name": "NL"
      },
      "employmentType" : "",
      "hiringOrganization" : {
        "@type" : "Organization",
        "name" : "Blauwtand",
        "sameAs" : "https://www.blauwtand.com"
      },
      "jobLocation": {
        "@type": "Place",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": '',
        "addressLocality": "",
        "addressRegion": "",
        "postalCode": "",
        "addressCountry": "NL"
        }
      },
      "baseSalary": {
        "@type": "MonetaryAmount",
        "currency": "EUR",
        "value": {
          "@type": "QuantitativeValue",
          "value": "",
          "unitText": "MONTH"
        }
      }
    }
    return this.getSafeHTML(obj)
  }

  public parseGoogleJob() {
    let employmentType = "FULL_TIME"

    switch(this.job.employment) {
      case employmentTypes[0]: employmentType = "FULL_TIME"; break
      case employmentTypes[1]: employmentType = "CONTRACTOR"; break
    }

    const salary = this.job.salary.static ? {
      "value": `${this.job.salary.static}`
    } : {
      "minValue": `${this.job.salary.min}`,
      "maxValue": `${this.job.salary.max}`,
    }

    const obj = {
      "@context" : "https://schema.org/",
      "@type" : "JobPosting",
      "title" : `${this.job.title}`,
      "description" : `${this.job.description}`,
      "identifier": {
        "@type": "PropertyValue",
        "name": "Blauwtand",
        "value": `${this.job.id}`
      },
      "datePosted" : `${this.job.publicationDate}`,
      "applicantLocationRequirements": {
        "@type": "Country",
        "name": "NL"
      },
      "employmentType" : `${employmentType}`,
      "hiringOrganization" : {
        "@type" : "Organization",
        "name" : "Blauwtand",
        "sameAs" : "https://www.blauwtand.com"
      },
      "jobLocation": {
        "@type": "Place",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": '',
        "addressLocality": `${this.job.location.city}`,
        "addressRegion": `${this.job.location.state}`,
        "postalCode": `${this.job.location.postalCode}`,
        "addressCountry": "NL"
        }
      },
      "baseSalary": {
        "@type": "MonetaryAmount",
        "currency": "EUR",
        "value": {
          "@type": "QuantitativeValue",
          ...salary,
          "unitText": "MONTH"
        }
      }
    }
    this.jsonLD = this.getSafeHTML(obj)
  }

  public viewJob(event: MouseEvent, job: JobCatsOne) {
    event.preventDefault()
    this.isLoadingJob = true
    this.isLoadingRelatedJobs = true
    this.relatedJobs = []
    this.sharedService.selectedJobId = job.id
    this.router.navigate([`../../view-job/${job.id}`], { relativeTo: this.activatedRoute })
    this.getJob(job.id)
  }

  public directApply(event: MouseEvent) {
    event.preventDefault()
    this.googleTagManagerService.pushTag({
      event: 'button_apply_immediately',
      page_title: this.titleService.getTitle(),
    });
    this.router.navigate(["direct-apply"], { relativeTo: this.activatedRoute })
  }

  private getSafeHTML(jsonLD: {[key: string]: any}): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : ''; // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public backToJobs(): void {
    this.router.navigate(["../.."], { relativeTo: this.activatedRoute })
  }

  public navigateTo(menuItem: string, isMobileMenu: boolean = false) {
    if(menuItem) {
      this.router.navigate([`../../../${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
    }
  }

  private scrollFade(): void {
    const spinner = this.spinnerRef?.nativeElement as HTMLElement
    const viewJob = this.viewJobRef?.nativeElement as HTMLElement

    if(viewJob && viewJob.getBoundingClientRect().y < (window.innerHeight) && viewJob.classList.contains("scroll-fade")) {
      viewJob.classList.remove("scroll-fade")
    }

    if(spinner && spinner.getBoundingClientRect().y < (window.innerHeight + 200) && spinner.classList.contains("scroll-fade")) {
      spinner.classList.remove("scroll-fade")
    }
  }
}
