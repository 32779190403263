import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Vacancy, dates, descriptions, locations, radii, remoteOptions, titles } from './mock-data.component';
import { SharedService } from './shared.service';
import { Utils, industryOptions, educationalLevels, employmentTypes, salaryTypes, hourTypes, locationKeywords, searchKeywords } from './utils';
import { JobCatsOne } from './vacancy-data.component';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.scss']
})
export class VacanciesComponent implements OnInit, AfterViewInit {
  @ViewChild("filterRef") filterRef: ElementRef
  @ViewChild("paginatorRef") paginatorRef: ElementRef
  @ViewChild("spinnerRef") spinnerRef: ElementRef
  @ViewChild("jobNotFoundRef") jobNotFoundRef: ElementRef
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChildren("vacanciesRef") vacanciesRef: QueryList<ElementRef>

  public isMobileMenuHidden = true
  public allVacancies: Vacancy[] = []
  public displayedVacancies: Vacancy[] = []
  public displayedJobsCatsOne: JobCatsOne[] = []
  public filteredVacancies: Vacancy[] = []
  public filteredJobsCatsOne: JobCatsOne[] = []
  public pageSizeOptions = [5, 10, 20, 40]
  public pageSize = this.pageSizeOptions[1]
  public pageIndex = 0
  public length: number
  public form: UntypedFormGroup
  public titles: string[]
  public descriptions: string[]
  public locations: string[]
  public radii: number[]
  public remoteOptions: string[]
  public educationalLevels: string[]
  public employmentTypes: string[]
  public salaryTypes: string[]
  public hourTypes: string[]
  public industryOptions: string[]
  public dates: string[]
  public isMobile = false
  public isFilterMenuHidden = true
  public isJobMenuHidden = true
  public selectedJob: JobCatsOne
  public isLoadingJobs = true
  public jobs: JobCatsOne[] = []
  public locationAutosuggest: string[] = []
  public searchKeywordAutosuggest: string[] = []
  public pageSizeNgFor: number[] = []
  public dateToDatePosted = Utils.dateToDatePosted
  public parseJobDescription = Utils.parseJobDescription
  public parseSalary = Utils.parseSalary
  public parseHours = Utils.parseHours
  public mapIndustries = Utils.mapIndustries

  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private httpClient: HttpClient,
    private readonly googleTagManagerService: GoogleTagManagerService,
    public sharedService: SharedService,
    ) {
      // this.allVacancies = generateMockVacancies(1000)
      // this.length = this.allVacancies.length
      this.titles = titles
      this.descriptions = descriptions
      this.locations = locations
      this.radii = radii
      this.remoteOptions = remoteOptions
      this.educationalLevels = educationalLevels
      this.employmentTypes = employmentTypes
      this.industryOptions = industryOptions
      this.dates = dates
      this.salaryTypes = salaryTypes
      this.hourTypes = hourTypes
      this.pageSizeNgFor = new Array(this.pageSize)
  }

  public get radius(): number | null { return this.form.get("radius")?.value }
  public get remote(): string | null { return this.form.get("remote")?.value }
  public get educationalLevel(): string | null { return this.form.get("educationalLevel")?.value }
  public get jobType(): string | null { return this.form.get("jobType")?.value }
  public get industry(): string | null { return this.form.get("industry")?.value }
  public get datePosted(): string | null { return this.form.get("datePosted")?.value }
  public get profession(): string | null { return this.form.get("profession")?.value?.toLowerCase().trim() }
  public get location(): string | null { return this.form.get("location")?.value }
  public get countryCode(): string | null { return this.form.get("countryCode")?.value }
  public get dateCreated(): string | null { return this.form.get("dateCreated")?.value }
  public get dateModified(): string | null { return this.form.get("dateModified")?.value }
  public get description(): string | null { return this.form.get("description")?.value }
  public get id(): string | null { return this.form.get("id")?.value }
  public get city(): string | null { return this.form.get("city")?.value }
  public get postalCode(): string | null { return this.form.get("postalCode")?.value }
  public get state(): string | null { return this.form.get("state")?.value }
  public get title(): string | null { return this.form.get("title")?.value?.toLowerCase().trim() }
  public get salary(): string | null { return this.form.get("salary")?.value }
  public get education(): string | null { return this.form.get("education")?.value }
  public get employment(): string | null { return this.form.get("employment")?.value }
  public get hours(): string | null { return this.form.get("hours")?.value ?? [] }
  public get industries(): string | null { return this.form.get("industries")?.value ?? [] }
  
  public get noResultsFound() {
    return !this.isLoadingJobs && this.displayedJobsCatsOne.length === 0
  }

  public get searchHistory(): string[] {
    if(localStorage.getItem("history")) {
      const history = JSON.parse(localStorage.getItem("history") ?? '')
      let profession = history?.profession as Array<string> ?? []
      profession = profession.filter(item => item.trim().length > 0)
      return profession
    }
    return []
  }

  public getJobs() {
    this.httpClient.get<{ jobs: JobCatsOne[]}>(`${Utils.getApiUrl()}/vacancies`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
    }).subscribe(response => {
      this.jobs = response.jobs
      this.length = this.jobs.length
      this.isLoadingJobs = false
      this.displayedJobsCatsOne = this.filteredJobsCatsOne = this.jobs
        .slice(0 * this.pageSize, 0 * this.pageSize + this.pageSize)
      this.parseMainPageSearch()
      this.filterJobs()
    })
  }

  public educationOrNA(job: JobCatsOne) {
    return job.education.length > 0 ? job.education : "N/A"
  }

  public employmentOrNA(job: JobCatsOne) {
    return job.employment.length > 0 ? job.employment : "N/A"
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — Voor professionals")
    this.initFormCatsOne()
    this.getJobs()

    this.form.get("location")?.valueChanges.subscribe((i: string) => {
      this.locationAutosuggest = []
      if(i.trim().toLowerCase() === "") { return }

      for(const a of locationKeywords) {
        const r = a.slice(0, i.length).toLowerCase()
        if(i.toLowerCase().includes(r)) {
          this.locationAutosuggest.push(a)
        }
      }
    })
    this.form.get("profession")?.valueChanges.subscribe((i: string) => {
      this.searchKeywordAutosuggest = []
      if(i.trim().toLowerCase() === "") { return }

      for(const a of searchKeywords) {
        const r = a.slice(0, i.length).toLowerCase()
        if(i.toLowerCase().includes(r)) {
          this.searchKeywordAutosuggest.push(a)
        }
      }
    })
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()
    if(window.innerWidth <= 767 ) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    if(window.innerWidth >= 1024) {
      this.isFilterMenuHidden = true
    }

    this.vacanciesRef.changes.subscribe(i => {
      const vacancies: Array<ElementRef> = (this.vacanciesRef as any)._results
      for(const vacancy of vacancies) {
        const v: HTMLElement = vacancy.nativeElement
        if(v && v.classList.contains("scroll-fade")) {
          v.classList.remove("scroll-fade")
        }
      }
    })

    window.addEventListener("resize", () => {
      if(window.innerWidth >= 1024) {
        this.isMobileMenuHidden = true
        this.isFilterMenuHidden = true
      }
      if(window.innerWidth <= 767 ) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }) 

    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    window.addEventListener("keypress", (event) => {
      if(event.key === "Enter") {
        Utils.saveSearchHistory(this.profession ?? "")
        this.autocomplete.closePanel();
      }
    })
    this.scrollFade()
  }

  public toggleFilterMenu(): void {
    this.isFilterMenuHidden = !this.isFilterMenuHidden
    this.sharedService.isMobileMenuButtonHidden = !this.sharedService.isMobileMenuButtonHidden
    // document.body.style.position = this.isFilterMenuHidden ? "" : "fixed"
  }

  public toggleJobMenu(job?: JobCatsOne): void {
    if(job) {
      this.selectedJob = job
    }
    this.isJobMenuHidden = !this.isJobMenuHidden
    this.sharedService.isMobileMenuButtonHidden = !this.sharedService.isMobileMenuButtonHidden
    document.body.style.overflow = this.isJobMenuHidden ? "auto" : "hidden"
  }

  public viewJob(event: MouseEvent, job: JobCatsOne) {
    event.preventDefault()
    this.sharedService.selectedJobId = job.id
    this.sharedService.filterData.profession = this.profession ?? ""
    this.sharedService.filterData.location = this.location ?? ""
    this.sharedService.filterData.datePosted = this.datePosted ?? ""
    this.sharedService.filterData.education = this.education ?? ""
    this.sharedService.filterData.salary = this.salary ?? ""
    this.sharedService.filterData.employment = this.employment ?? ""
    this.sharedService.filterData.hours = this.hours ?? ""
    this.sharedService.filterData.industries = this.industries ?? ""
    this.router.navigate(['view-job', job.id], { relativeTo: this.activatedRoute })
  }

  public apply(event: MouseEvent) {
    event.preventDefault()
    this.googleTagManagerService.pushTag({
      event: 'button_open_application',
      page_title: this.titleService.getTitle(),
    });
    this.router.navigate(['apply'], { relativeTo: this.activatedRoute })
  }

  public pageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex
    this.displayedJobsCatsOne = this.filteredJobsCatsOne
      .slice(event.pageIndex * event.pageSize, (event.pageIndex * event.pageSize) + event.pageSize)
      .sort((a, b) =>  new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime())
    window.scrollTo(0, 0)
  }

  public clearProfession(): void {
    this.form.patchValue({ profession: "" })
  }

  public clearLocation(): void {
    this.form.patchValue({ location: "" })
  }

  public clearAll(): void {
    this.form.patchValue({ 
      profession: "",
      location: "",
      datePosted: "",
      industry: "",
      education: "",
      employment: "",
      salary: "",
      hours: "",
      industries: "",
    })

    this.sharedService.resetFilterData()
  }

  public showDivider(index: number): boolean {
    return index < this.searchHistory.length - 1
  }

  public setProfession(word: string): void {
    this.form.patchValue({ profession: word })
  }

  public search(): void {
    Utils.saveSearchHistory(this.profession ?? "")
  }

  private scrollFade(): void {
    const filter = this.filterRef?.nativeElement as HTMLElement
    const vacancies: Array<ElementRef> = (this.vacanciesRef as any)._results
    const paginator = this.paginatorRef?.nativeElement as HTMLElement
    const spinner = this.spinnerRef?.nativeElement as HTMLElement
    const jobNotFound = this.jobNotFoundRef?.nativeElement as HTMLElement

    if(filter && filter.getBoundingClientRect().y < window.innerHeight && filter.classList.contains("scroll-fade")) {
      filter.classList.remove("scroll-fade")
    }

    for(const vacancy of vacancies) {
      const v: HTMLElement = vacancy.nativeElement
      if(v && v.getBoundingClientRect().y < (window.innerHeight + 400) && v.classList.contains("scroll-fade")) {
        v.classList.remove("scroll-fade")
      }
    }

    if(spinner && spinner.getBoundingClientRect().y < (window.innerHeight + 400) && spinner.classList.contains("scroll-fade")) {
      spinner.classList.remove("scroll-fade")
    }

    if(paginator && paginator.getBoundingClientRect().y < window.innerHeight && paginator.classList.contains("scroll-fade")) {
      paginator.classList.remove("scroll-fade")
    }

    if(jobNotFound && jobNotFound.getBoundingClientRect().y < window.innerHeight && jobNotFound.classList.contains("scroll-fade")) {
      jobNotFound.classList.remove("scroll-fade")
    }
  }

  public toggleMobileMenu(): void {
    this.isMobileMenuHidden = !this.isMobileMenuHidden
    document.body.style.position = this.isMobileMenuHidden ? "" : "fixed"
  }

  public routeTo(menuItem: string, isMobileMenu: boolean = false): void {
    setTimeout(() => {
      this.router.navigate([`${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
    }, this.sharedService.transitionDelay)
  }

  private filterJobs() {
    this.filteredJobsCatsOne = this.jobs.filter(vacancy => {
      let skip = false
      const developerKeywords = ["software ontwikkelaar", "software engineer", "programmeur", "developer", "ontwikkelaar"]
      const systemKeywords = ["systeembeheerder", "system engineer"]

      if(this.profession && this.profession !== '') {
        for(let i of developerKeywords) {
          if(this.profession.includes(i)) {
            skip = true
            if(!vacancy.title.toLowerCase().includes("software ontwikkelaar")
              && !vacancy.title.toLowerCase().includes("software engineer")
              && !vacancy.title.toLowerCase().includes("developer")
              && !vacancy.title.toLowerCase().includes("programmeur")) {
                return false
              }
          }
        }

        for(let i of systemKeywords) {
          if(this.profession.includes(i)) {
            skip = true
            if(!vacancy.title.toLowerCase().includes("systeembeheerder")
              && !vacancy.title.toLowerCase().includes("system engineer")) {
                return false
              }
          }
        }

        if(!vacancy.title.toLowerCase().includes(this.profession.toLowerCase())) {
          if(!skip) {
            return false
          }
        }
      }

      if(this.location 
        && this.location !== ''
        && !vacancy.location.city.toLowerCase().replace(new RegExp("[- ]+"), "").includes(this.location.replace(new RegExp("[- ]+"), "").toLowerCase())
        && !vacancy.location.state.toLowerCase().replace(new RegExp("[- ]+"), "").includes(this.location.replace(new RegExp("[- ]+"), "").toLowerCase())
        && !vacancy.location.postalCode.trim().replace(new RegExp("[ ]+"), "").toLowerCase().includes(this.location.trim().replace(new RegExp("[ ]+"), "").toLowerCase()))
        {
        return false
      }
      if(this.industry && vacancy.industry.toLowerCase() !== this.industry.toLowerCase()) { 
        return false
      }
      if(this.education && vacancy.education?.toLowerCase() !== this.education?.toLowerCase()) { 
        return false
      }
      if(this.employment && vacancy.employment?.toLowerCase() !== this.employment?.toLowerCase()) { 
        return false
      }

      const salaryNumber = Utils.salaryToNumber(this.salary ?? '')
      if(vacancy.salary.static && !isNaN(Number(vacancy.salary.static))) {
        if(salaryNumber && (Number(vacancy.salary.static) > salaryNumber)) { 
          return false
        }
      }
      if(vacancy.salary.max) {
        if(salaryNumber && salaryNumber > (Number(vacancy.salary.max))) { 
          return false
        }
      }

      if(Array.isArray(this.hours) && this.hours.length > 0) {
        if(!this.hours.every(i => vacancy.hours.includes(i))) {
          return false
        }
      }

      if(Array.isArray(this.industries) && this.industries.length > 0) {
        let hasIndustry = false
        for(const i of this.industries) {
          if (vacancy.industries.some(j => j == i)) {
            hasIndustry = true
            break
          }
        }
        if (!hasIndustry) {
          return false
        }
      }

      if(this.datePosted === "Afgelopen 24 uur" && new Date(vacancy.publicationDate) < new Date(new Date().getTime() - (1000 * 60 * 60 * 24))) {
        return false
      }

      if(this.datePosted === "Afgelopen 3 dagen" && new Date(vacancy.publicationDate) < new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 3))) {
        return false
      }

      if(this.datePosted === "Afgelopen 7 dagen" && new Date(vacancy.publicationDate) < new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7))) {
        return false
      }

      if(this.datePosted === "Afgelopen 14 dagen" && new Date(vacancy.publicationDate) < new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 14))) {
        return false
      }

      if(this.datePosted === "Meer dan 14 dagen geleden" && new Date(vacancy.publicationDate) > new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 14))) {
        return false
      }

      return true
    })

    this.filteredJobsCatsOne = this.filteredJobsCatsOne.map(i => {
      let score = 0
      if(this.profession && i.title.toLowerCase().includes(this.profession)) {
        score++
      }
      return {...i, score}
    }).sort((a, b) => b.score - a.score)

    this.length = this.filteredJobsCatsOne.length
    this.displayedJobsCatsOne = this.filteredJobsCatsOne
      .slice(this.pageIndex * this.pageSize, this.pageIndex * this.pageSize + this.pageSize)
    this.form.patchValue(this.form.value, {emitEvent: false})
  }

  private initFormCatsOne(): void {
    this.form = this.formBuilder.group({
      industry: this.formBuilder.control(""),
      countryCode: this.formBuilder.control(""),
      dateCreated: this.formBuilder.control(""),
      dateModified: this.formBuilder.control(""),
      description: this.formBuilder.control(""),
      id: this.formBuilder.control(""),
      city: this.formBuilder.control(""),
      postalCode: this.formBuilder.control(""),
      state: this.formBuilder.control(""),
      title: this.formBuilder.control(""),
      salary: this.formBuilder.control(this.sharedService?.filterData?.salary ?? ""),
      profession: this.formBuilder.control(this.sharedService?.filterData?.profession ?? ""),
      location: this.formBuilder.control(this.sharedService?.filterData?.location ?? ""),
      jobType: this.formBuilder.control(""),
      education: this.formBuilder.control(this.sharedService?.filterData?.education ?? ""),
      employment: this.formBuilder.control(this.sharedService?.filterData?.employment ?? ""),
      hours: this.formBuilder.control(this.sharedService?.filterData?.hours ?? ""),
      industries: this.formBuilder.control(this.sharedService?.filterData?.industries ?? ""),
      radius: this.formBuilder.control(0),
      datePosted: this.formBuilder.control(this.sharedService?.filterData?.datePosted ?? ""),
    })

    this.form.valueChanges.subscribe(_ => {
      this.filterJobs()
      this.filteredJobsCatsOne = this.filteredJobsCatsOne.map(i => {
        let score = 0
        if(this.profession && i.title.toLowerCase().includes(this.profession)) {
          score++
        }
        return {...i, score}
      }).sort((a, b) => b.score - a.score)

      this.length = this.filteredJobsCatsOne.length
      this.displayedJobsCatsOne = this.filteredJobsCatsOne
        .slice(this.pageIndex * this.pageSize, this.pageIndex * this.pageSize + this.pageSize)
      this.form.patchValue(this.form.value, {emitEvent: false})
    })
  }

  private parseMainPageSearch() {
    const profession = this.activatedRoute.snapshot.paramMap.get("profession")
    const location = this.activatedRoute.snapshot.paramMap.get("location")
    const patch = Object.assign({},
      profession === null ? null : {profession},
      location === null ? null : {location},
    );

    if(Object.keys(patch || {}).length > 0) {
      this.form.patchValue(patch, { emitEvent: true })
    }
  }
}
