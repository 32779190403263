import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from './shared.service';
import { Utils } from './utils';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild("footerRef") footerRef: ElementRef
  public phoneCall = Utils.phoneCall
  public phoneNumber = Utils.phoneNumber
  public email = Utils.email
  public whatsapp = Utils.whatsapp
  public whatsAppNumber = Utils.whatsAppNumber
  public redirectTo: (name: string) => void = Utils.redirectTo

  public constructor(
    public readonly elementRef: ElementRef,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
  ) {}
  
  public get currentYear() {
    return new Date().getFullYear()
  }
  
  public async ngOnInit(): Promise<void> {
    await Utils.timeout()
    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    this.scrollFade()
  }

  ngAfterViewInit(): void {
    this.scrollFade()
  }

  public navigateTo(menuItem: string, isMobileMenu: boolean = false) {
    if(menuItem) {
      this.router.navigate([`../${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
    }
  }

  private scrollFade(): void {
    const footer = (this.footerRef.nativeElement as HTMLElement)
    const footerTop = footer.querySelector(".top")
    const footerBottom = footer.querySelector(".bottom")

    if(footerTop && footerTop.getBoundingClientRect().y < window.innerHeight && footerTop.classList.contains("scroll-fade")) {
      footerTop.classList.remove("scroll-fade")
    }

    if(footerBottom && footerBottom.getBoundingClientRect().y < window.innerHeight && footerBottom.classList.contains("scroll-fade")) {
      footerBottom.classList.remove("scroll-fade")
    }
  }
}
