<div class="employers"></div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text"></div>
  </div>
</div>
<div class="view-job">
  <div class="job-container">
    <div class="vacancies">
      <div #vacancyRef class="vacancy">
        <div class="applied">
          <div class="applied-header">
            <icon name="done"></icon>
            <span class="text">Sollicitatie ontvangen!</span>
          </div>
          <div class="applied-paragraph">
            Hartelijk dank voor het indienen van je sollicitatie. We hebben je
            sollicitatie ontvangen en zullen binnenkort contact met je opnemen.
          </div>
          <div class="applied-paragraph last">Ontdek meer en volg ons op <span class="link-container"><icon name="linkedin"></icon><a class="link" href="https://www.linkedin.com/company/blauwtand/">LinkedIn</a></span>!</div>
          <div>
            <button
              mat-raised-button
              color="primary"
              class="no-shadow"
              (click)="navigateTo('home')"
            >
              <span
                >We gaan vanzelf terug in
                <span class="countdown">{{ countdown }}</span
                >s</span
              >
              <icon name="navigate_next"></icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
