export class Location {
  city: string
  postalCode: string
  state: string
}

export class VacancyCatsOne {
  public industry: string
  public countryCode: string
  public dateCreated: Date
  public dateUpdated: Date
  public description: string
  public id: number
  public location: Location
  public title: string
  public salary: string
}

export class Vacancy {
  public constructor(
    public title: string,
    public description: string,
    public location: string,
    public radius: number,
    public remote: string,
    public educationalLevel: string,
    public jobType: string,
    public industry: string,
    public datePosted: Date,
  ) {
  }
}

export const titles = [
  "Accountmanager Software",
  "Embedded Software Engineer",
  "C++ Software Engineer",
  "Python Engineer Scientific Software",
  "Production Engineer",
  "Optical Design Engineer",
  "Embedded Software Architect",
  "Embedded Software Engineer",
  "Software Test Automation Engineer",
  "Scientific Software Engineer"
]

export const descriptions = [
  "Kom jij ons netwerk binnen het Software domein verder uitbreiden én leiding geven aan je eigen team? De schakel zijn tussen de persoonlijke drijfveren van je medewerkers en opdrachtgevers. Meedenken en maatwerk leveren. Kijken wat iemand nodig heeft en wil leren; vakinhoudelijk maar vooral ook persoonlijk. En hem of haar alles bieden om dit mogelijk te maken. Dát is voor ons Tech with a heartbeat!",
  "Do you have a passion for the hightech industry? And do you want to work on innovative hightech projects that ensure that our customers build up a lasting lead in the world of technology? While at the same time you will be working for a company where you are at the center and where your personal development is very important to us? Then you are the candidate we are looking for! Being employed with ENTER you can work on challenging Research & Development projects with a variety of hightech companies in Brainport, Eindhoven",
  "Do you want to work on innovative, high tech projects that ensure that our customers build up a lasting lead in the world of technology? Technological innovation is the future! If it would be up to us: your future! At ENTER you can work on interesting R&D projects in a variety of industries.",
  "Do you have a passion for the hightech industry? We do as well. A more dynamic field does not exist. Technological innovation is the future! If it would be up to us: your future! Being employed with ENTER you can work on challenging Research & Development projects with a variety of hightech companies in Brainport Eindhoven, while at the same time you will be working for a company where you are at the center and where your personal development is very important.",
  "Gaat jouw hart sneller kloppen van mechatronische systemen en het inrichten en optimaliseren van productieprocessen? Voor een goede klant in de hightech industrie ben ik op zoek naar een Production Engineer. Wil jij werken aan innovatieve hightech projecten die ervoor zorgen dat onze klant een blijvende voorsprong opbouwt in de wereld van technologie? Terwijl je tegelijkertijd werkt voor een bedrijf waar jij centraal staat en waar we jouw persoonlijke ontwikkeling heel belangrijk vinden? Dan ben jij de kandidaat die we zoeken!",
]

export const locations = [
  "Eindhoven",
  "Delft",
  "Rotterdam",
  "Amsterdam",
  "Tilburg",
  "Enschede",
  "Utrecht",
  "Maastricht"
]

export const radii = [
  0, 5, 10, 20, 25, 50, 100, 500
]

export const remoteOptions = [ "Ja", "Nee" ]

const educationalLevels = [
  "Mbo",
  "Bachelor",
  "Master",
]

const jobTypes = [
  "Full-time",
  "Part-time",
  "Stage",
]

const industries = [
  // "Software & informatietechnologie",
  // "Sales & retail",
  // "Media, design & cultuur",
  // "Marketing, communicatie & reclame",
  // "Onderzoek & wetenschap",
  // "Onderwijs & instructie",
  // "Zorg & welzijn",
  // "Food & beverage",
  "Information Technology"
]

export const dates = [
  "Afgelopen 24 uur",
  "Afgelopen 3 dagen",
  "Afgelopen 7 dagen",
  "Afgelopen 14 dagen",
  "Meer dan 14 dagen geleden",
]

function randomInt(max: number) {
  return Math.floor(Math.random() * max)
}

function randomDate(start: Date, end: Date) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const month = new Date(2021, 8, 5).getTime() - new Date(2021, 7, 5).getTime()

export function generateMockVacancies(amount: number) {
  let vacancies: Vacancy[] = []
  for(let i = 0; i < amount; i++) {
    vacancies.push(new Vacancy(
      titles[randomInt(titles.length)],
      descriptions[randomInt(descriptions.length)],
      locations[randomInt(locations.length)],
      radii[randomInt(radii.length)],
      remoteOptions[randomInt(remoteOptions.length)],
      educationalLevels[randomInt(educationalLevels.length)],
      jobTypes[randomInt(jobTypes.length)],
      industries[randomInt(industries.length)],
      randomDate(new Date(Date.now() - month), new Date(Date.now())),
    ))
  }
  return vacancies
}
