import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from './shared.service';
import { JobCatsOne } from './vacancy-data.component';
import { Utils } from "./utils"
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit, AfterViewInit {
  @ViewChild("spinnerRef") spinnerRef: ElementRef
  @ViewChild("checkboxErrorRef") checkboxErrorRef: MatCheckbox
  @ViewChild("resumeErrorRef") resumeErrorRef: ElementRef
  @ViewChild("resumeRef") set resumeRef(value: ElementRef) {
    if(value) {
    this._resumeRef = value
    value.nativeElement.addEventListener("dragover", (event: DragEvent) => {
      this.dropResume = true
      value.nativeElement.classList.add("drop-color")
      event.preventDefault();
      event.stopPropagation();
    })

    value.nativeElement.addEventListener("dragleave", (event: DragEvent) => {
      this.dropResume = false
      if(value.nativeElement.classList.contains("drop-color")) {
        value.nativeElement.classList.remove("drop-color")
      }
      event.preventDefault();
      event.stopPropagation();
    })

    value.nativeElement.addEventListener("drop", (event: DragEvent) => {
      this.dropResume = false
      if(value.nativeElement.classList.contains("drop-color")) {
        value.nativeElement.classList.remove("drop-color")
      }

      let files = Array.from(event.dataTransfer?.files ?? [])
      files = files.filter(i => i.type === "application/pdf" || i.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") 
      this.resume = files[0]
      event.preventDefault();
      event.stopPropagation();
    })
  }
  }

  @ViewChild("motivationLetterRef") set motivationLetterRef(value: ElementRef) {
    if(value) {
    this._motivationLetterRef = value
    value.nativeElement.addEventListener("dragover", (event: DragEvent) => {
      this.dropMotivationLetter = true
      value.nativeElement.classList.add("drop-color")
      event.preventDefault();
      event.stopPropagation();
    })

    value.nativeElement.addEventListener("dragleave", (event: DragEvent) => {
      this.dropMotivationLetter = false
      if(value.nativeElement.classList.contains("drop-color")) {
        value.nativeElement.classList.remove("drop-color")
      }
      event.preventDefault();
      event.stopPropagation();
    })

    value.nativeElement.addEventListener("drop", (event: DragEvent) => {
      this.dropMotivationLetter = false
      if(value.nativeElement.classList.contains("drop-color")) {
        value.nativeElement.classList.remove("drop-color")
      }

      let files = Array.from(event.dataTransfer?.files ?? [])
      files = files.filter(i => i.type === "application/pdf" || i.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") 
      this.motivationLetter = files[0]
      event.preventDefault();
      event.stopPropagation();
    })
  }
  }

  public get sendApplicationText() {
    return this.isSendingApplication ? "Aan het versturen" : "Verstuur sollicitatie"
  }

  public isSendingApplication = false
  public isPrivacyStatementChecked = false
  public applied = false
  public _motivationLetterRef: ElementRef
  public _resumeRef: ElementRef
  public dropResume = false
  public dropMotivationLetter = false
  public dropItError = false
  public job: JobCatsOne
  public isLoadingJob = false
  public isLoadingRelatedJobs = true
  public isMobile = false
  public jsonLD: SafeHtml;
  public form: UntypedFormGroup
  public files: Array<File> = []
  public resume?: File
  public motivationLetter?: File
  public time = 10
  public interval: any
  public get countdown(): number {
    return this.time <= 0 ? 0 : this.time
  }
  public dateToDatePosted = Utils.dateToDatePosted
  public parseJobDescription = Utils.parseJobDescription
  public phoneCall = Utils.phoneCall
  public parseSalary = Utils.parseSalary
  public parseHours = Utils.parseHours
  public mapIndustries = Utils.mapIndustries

  public emailPattern = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}"
  public phonePattern = "(([+]*[(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))[ ]*\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})"
  public postalCodePattern = "^[1-9][0-9]{3}[[ ]]{0,1}(?!SA|SD|SS)[A-Z]{2}$"

  public constructor(
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    public sharedService: SharedService,
    private readonly formBuilder: UntypedFormBuilder,
  ) {}

  public get firstName() { return this.form.get("firstName")?.value }
  public get lastName() { return this.form.get("lastName")?.value }
  public get email() { return this.form.get("email")?.value }
  public get address() { return this.form.get("address")?.value }
  public get location() { return this.form.get("location")?.value }
  public get postalCode() { return this.form.get("postalCode")?.value }
  public get phone() { return this.form.get("phone")?.value }


  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — Open sollicitatie")
    this.initForm()
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()
    if(window.innerWidth <= 767 ) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    window.addEventListener("resize", () => {
      if(window.innerWidth <= 767 ) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    })
    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    this.scrollFade()
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      firstName: this.formBuilder.control("", [Validators.required, Validators.maxLength(50)]),
      lastName: this.formBuilder.control("", [Validators.required, Validators.maxLength(50)]),
      email: this.formBuilder.control("", [Validators.required, Validators.email]),
      address: this.formBuilder.control("", Validators.maxLength(50)),
      location: this.formBuilder.control("", Validators.maxLength(50)),
      postalCode: this.formBuilder.control("", Validators.pattern(this.postalCodePattern)),
      phone: this.formBuilder.control("+31", Validators.pattern(this.phonePattern)),
    })

    this.form.get("phone")?.valueChanges.subscribe((i: string) => {
      i = i.replace(new RegExp("[+31 ]+"), "")
      this.form.patchValue({
        phone: `+31 ${i}`
      }, { emitEvent: false, onlySelf: true })
    })
  }

  public changeCheckbox(event: MatCheckboxChange ) {
    this.isPrivacyStatementChecked = event.checked
  }

  public async apply() {
    if(this.isSendingApplication) { return }
    if(!this.form.valid) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }

    if(!this.isPrivacyStatementChecked) {
      if(!this.checkboxErrorRef._elementRef.nativeElement.classList.contains("error-checkbox")) {
        this.checkboxErrorRef._elementRef.nativeElement.classList.add("error-checkbox")
      }
    } else {
        this.checkboxErrorRef._elementRef.nativeElement.classList.remove("error-checkbox")
    }

    if(!this.resume) {
      this._resumeRef.nativeElement.classList.add("resume-invalid")
      if(this.resumeErrorRef.nativeElement.classList.contains("hidden")) {
        this.resumeErrorRef.nativeElement.classList.remove("hidden")
      }
    } else {
      this.resumeErrorRef.nativeElement.classList.add("hidden")
      if(this._resumeRef.nativeElement.classList.contains("resume-invalid")) {
        this._resumeRef.nativeElement.classList.remove("resume-invalid")
      }
    }

    if(!this.form.valid || !this.resume || !this.isPrivacyStatementChecked) {
      return
    }

    const formData = new FormData()
    formData.append("resume", this.resume, this.resume.name)
    if(this.motivationLetter) {
      formData.append("motivationLetter", this.motivationLetter, this.motivationLetter.name)
    }
    formData.append("fields", JSON.stringify({...this.form.getRawValue(), jobId: "795055", jobTitle: "Open sollicitatie"}))
    this.isSendingApplication = true
    this.httpClient.post(`${Utils.getApiUrl()}/direct-apply`, 
    formData, {
        headers: new HttpHeaders({
        }),
      }
    )
    .subscribe(i => {
      this.isSendingApplication = false
      const result = i as { result: string }
      if(result) {
        this.applied = true
        this.interval = setInterval(() => {
          this.time -= 1
          if(this.time <= 0) {
            if(this.interval) {
              clearInterval(this.interval)
            }
            this.navigateTo("home")
          }
        }, 1000)
      }
    })
    
  }

  public file: File

  public deleteResume(event: any) {
    this.resume = undefined
  }

  public deleteMotivationLetter(event: any) {
    this.motivationLetter = undefined
  }

  public resumeChange(event: any) {
    const fileList: FileList = event.target.files
    this.files = Array.from(fileList ?? [])
    this.resume = this.files[0]
  }

  public motivationLetterChange(event: any) {
    const fileList: FileList = event.target.files
    this.files = Array.from(fileList ?? [])
    this.motivationLetter = this.files[0]
  }

  public clearFirstName() {
    this.form.patchValue({ firstName: ""})
  }

  public clearLastName() {
    this.form.patchValue({ lastName: ""})
  }

  public clearEmail() {
    this.form.patchValue({ email: ""})
  }

  public clearAddress() {
    this.form.patchValue({ address: ""})
  }

  public clearLocation() {
    this.form.patchValue({ location: ""})
  }

  public clearPostalCode() {
    this.form.patchValue({ postalCode: ""})
  }

  public clearPhone() {
    this.form.patchValue({ phone: ""})
  }

  public backToJobs(): void {
    this.router.navigate(["../.."], { relativeTo: this.activatedRoute })
  }

  public navigateTo(menuItem: string) {
    if(this.interval) {
      clearInterval(this.interval)
    }
    if(menuItem) {
      this.router.navigate([`../../${menuItem}`], { relativeTo: this.activatedRoute })
    }
  }

  private scrollFade(): void {
    const spinner = this.spinnerRef?.nativeElement as HTMLElement
    if(spinner && spinner.getBoundingClientRect().y < (window.innerHeight + 200) && spinner.classList.contains("scroll-fade")) {
      spinner.classList.remove("scroll-fade")
    }
  }
}
