<div class="search-vacancy">
  <!-- <div class="search-vacancy-bg">
    <picture>
      <source srcset="./assets/blauwtand_main.webp" type="image/webp">
      <source srcset="./assets/blauwtand_main.jpg" type="image/jpeg"> 
      <img src="./assets/blauwtand_main.jpg" (load)="loadedImage($event)" alt="Blauwtand header">
    </picture>
  </div> -->
  <div class="top-container">
  <div class="search-container">
    <!-- <div class="search-text">Vacatures</div>
    <div class="search-rectangle"></div> -->
    <div class="search-box">
      <div class="search-window" [formGroup]="form">
        <div class="item top">
          <div class="item-header">Zoekterm</div>
          <mat-form-field class="profession" appearance="fill">
            <mat-label>Functie</mat-label>
            <input type="text"
                   matInput
                   formControlName="profession"
                   [matAutocomplete]="auto1">
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option *ngFor="let searchItem of searchKeywordAutosuggest" [value]="searchItem">
                {{searchItem}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="item left">
          <div class="item-header">Locatie</div>
          <mat-form-field class="location" appearance="fill">
            <mat-label>Stad of provincie</mat-label>
            <input type="text"
                   matInput
                   formControlName="location"
                   [matAutocomplete]="auto2">
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngFor="let locationItem of locationAutosuggest" [value]="locationItem">
                {{locationItem}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <!-- <div class="item right">
          <mat-form-field class="distance" appearance="fill">
            <mat-label>Radius</mat-label>
            <mat-select formControlName="radius">
              <mat-option *ngFor="let radius of radii" [value]="radius">
                {{radius}} km
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="item bottom">
          <div class="item-header"></div>
          <button mat-raised-button color="primary" (click)="search()" class="no-shadow">
            <span>Zoeken</span>
            <icon name="search"></icon>
          </button>
        </div>
      </div>
      <div class="history" *ngIf="searchHistory.length > 0">
        <div class="history-text">Zoekterm geschiedenis:</div>
        <div class="keyword" *ngFor="let word of searchHistory.reverse(); let i = index">
          <span class="word" (click)="setProfession(word)">{{ word }}</span>
          <span *ngIf="showDivider(i)" class="divider"></span>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<div class="connecting-container">
<div class="connecting scroll-fade">
  <div class="c-header">Klaar voor een volgende stap in jouw carrière? Wij maken de connectie tussen jou en jouw nieuwe werkgever.</div>
  <div class="c-text">Blauwtand is dé plek voor het verbinden van bedrijven en IT Professionals. Wij hebben voor jou de uitdagendste opties voor jouw volgende stap in de IT. Bij ons vind je wat je zoekt: een nieuwe baan of een nieuwe opdracht!</div>
  <div class="c-blocks">
    <div class="left">
      <picture>
        <!-- <source srcset="./assets/blauwtand_professionals_cropped.webp" type="image/webp">
        <source srcset="./assets/blauwtand_professionals_cropped.png" type="image/png"> 
        <img class="left-image" src="./assets/blauwtand_professionals_cropped.png" alt="Vacatures"> -->
        <img class="left-image" src="./assets/IMG_0686 - Bureau.JPEG" alt="Vacatures">
      </picture>
      <div class="left-block">
        <div class="left-header">
          Vind een passende organisatie die matcht met jouw wensen en doelen
        </div>
        <div class="left-text">
          <div class="paragraph">Wij kwalificeren jouw kennis en kunde niet puur en alleen op aantoonbare zaken (certificaten, diploma’s of cursussen), maar zijn benieuwd naar de mens achter het CV. </div>
          <div class="paragraph">Waar liggen jouw ambities en hoe kan of wil jij deze gaan bereiken? Wat is de ervaring die je al hebt? En welke organisatie zou jij aangeven als dé perfecte werkplek?</div>
          <span class="paragraph">Neem gerust contact op met Wesley Boon - Recruitment Manager via <span class="link-container"><icon name="whatsapp"></icon><a class="link" (click)="whatsapp('31798080328')">{{ whatsAppNumber }}</a></span> of plan een eerste kennismaking in met één van de specialisten van Blauwtand.</span>
        </div>
        <div class="left-bottom">
          <button mat-raised-button color="primary" class="button-color-primary no-shadow" (click)="routeTo('vacancies')">
            <span>Vacatures</span>
            <icon name="navigate_next"></icon>
          </button>
        </div>
      </div>
    </div>
    <div class="right">
      <picture>
        <!-- <source srcset="./assets/blauwtand_employers_cropped.webp" type="image/webp">
        <source srcset="./assets/blauwtand_employers_cropped.png" type="image/png">  -->
        <!-- <img class="right-image" src="./assets/blauwtand_employers_cropped.png" alt="Voor opdrachtgevers"> -->
        <img class="right-image" src="./assets/IMG_0869 - Scrumboard.JPEG" alt="Voor opdrachtgevers">
      </picture>
      <div class="right-block">
        <div class="right-header">Verkrijg de profielen van de best passende IT Professional op jouw vacature</div>
        <div class="right-text">
          <div class="paragraph last">Op zoek naar een IT professional? Maar weet je niet waar je de juiste talenten voor jouw organisatie kunt vinden? Wij zijn het juiste adres voor jou! Bij Blauwtand vind je de échte connectie met IT Professionals die matchen met jouw eisen en wensen.</div>
        </div>
        <div class="right-bottom">
          <button mat-raised-button color="primary" class="button-color-primary no-shadow" (click)="routeTo('employers')">
            <span>Voor opdrachtgevers</span>
            <icon name="navigate_next"></icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>