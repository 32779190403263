import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { Utils } from '../utils';
import { SocketIoService } from '../socketio.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { callback } from 'chart.js/dist/helpers/helpers.core';

@Component({
  selector: 'logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("containerRef") containerRef: ElementRef
  @ViewChild("logContainerRef") logContainerRef: ElementRef
  @ViewChild("chartAllRef") chartAllRef: ElementRef
  @ViewChild("chartVacanciesRef") chartVacanciesRef: ElementRef
  @ViewChild("chartDirectApplyRef") chartDirectApplyRef: ElementRef
  logs: { date: string, time: string, content: string }[] = []
  filteredLogs: { date: string, time: string, content: string }[] = []
  form: UntypedFormGroup
  maxLogs = 250
  isMobile = false
  key = ''
  hasChartData = false

  private _apiCallAllChart: Chart;
  private _apiCallVacanciesChart: Chart;
  private _apiCallDirectApplyChart: Chart;
  private _unsubscribeAll = new Subject()

  public get formSearch() {
    return this.form.get("search")
  }

  public get hasApiCallAll() {
    return this._apiCallAllChart?.data?.datasets?.[0]?.data?.length > 0
  }

  public get hasApiCallVacancies() {
    return this._apiCallVacanciesChart?.data?.datasets?.[0]?.data?.length > 0
  }

  public get hasApiCallDirectApply() {
    return this._apiCallDirectApplyChart?.data?.datasets?.[0]?.data?.length > 0
  }
  
  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly socketIOService: SocketIoService,
    private readonly httpClient: HttpClient,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
  ) {}

  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — API insights")
    this.setMobileBreakpoint()
    Chart.register(...registerables, )

    this.key = prompt("Voer een wachtwoord in om toegang te krijgen tot de Blauwtand API logs.") ?? ""
    
    // socket logs
    this.socketIOService.getLogs().subscribe((log => {
      this.logs.push({
        content: log,
        date: this.datePipe.transform(new Date(), "dd/MM/yyyy")!,
        time: this.datePipe.transform(new Date(), "HH:mm:ss")!,
      })

      if (this.logs.length > this.maxLogs) {
        this.logs = this.logs.slice(1)
      }
      
      this.applySearchFilter(this.formSearch!.value)
      this.scrollLogContainerToBottom()
    }))

    this.socketIOService.joinRoom(this.key, "logsRoom")

    this.httpClient.get<{ id: number, message: string, timestamp: string }[]>(`${Utils.getApiUrl()}/logs`, {
      params: {
        amount: this.maxLogs,
        key: this.key,
      }
    })
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((logs => {
      if (!Array.isArray(logs)) {
        this.snackBar.open("Het ingevoerde wachtwoord is onjuist. Er wordt geen informatie weergegeven.", "Sluiten")
        return
      }

      for(const log of logs) {
        this.logs.push({
          content: log.message,
          date: this.datePipe.transform(new Date(log.timestamp), "dd/MM/yyyy")!,
          time: this.datePipe.transform(new Date(log.timestamp), "HH:mm:ss")!,
        })
      }

      this.applySearchFilter(this.formSearch?.value)
      this.changeDetectorRef.detectChanges()
      this.scrollLogContainerToBottom(true)
    }))

    // socket apicall
    this.socketIOService.getApiCallAll().subscribe((result => {
      this.createChart(
        result.map(i => this.datePipe.transform(new Date(i.timestamp), "dd/MM HH:mm")!), 
        result.map(i => i.amount),
        this.chartAllRef,
        this._apiCallAllChart,
        "Alle endpoints",
        'API calls alle endpoints',
        (chartCanvas, config) => { this._apiCallAllChart = new Chart(chartCanvas, config)}
      )
    }))

    this.socketIOService.getApiCallVacancies().subscribe((result => {
      this.createChart(
        result.map(i => this.datePipe.transform(new Date(i.timestamp), "dd/MM HH:mm")!), 
        result.map(i => i.amount),
        this.chartAllRef,
        this._apiCallVacanciesChart,
        "GET /vacancies",
        'API calls GET /vacancies',
        (chartCanvas, config) => { this._apiCallVacanciesChart = new Chart(chartCanvas, config)}
      )
    }))

    this.socketIOService.getApiCallDirectApply().subscribe((result => {
      this.createChart(
        result.map(i => this.datePipe.transform(new Date(i.timestamp), "dd/MM HH:mm")!), 
        result.map(i => i.amount),
        this.chartAllRef,
        this._apiCallDirectApplyChart,
        "POST /direct-apply",
        'API calls GET /direct-apply',
        (chartCanvas, config) => { this._apiCallDirectApplyChart = new Chart(chartCanvas, config)}
      )
    }))

    // form
    this.form = this.formBuilder.group({
      search: [""]
    })

    this.formSearch?.valueChanges.subscribe(search => {
      this.applySearchFilter(search)
    })
  }

  public applySearchFilter(search: string | null) {
    if (!search || search?.trim().length === 0) {
      this.filteredLogs = this.logs
      return
    }

    this.filteredLogs = this.logs.filter(log => {
      return log.content.trim().toLowerCase().includes(search.trim().toLowerCase())
    })
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next(null)
    this._unsubscribeAll.complete()
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()

    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    this.scrollFade()
    this.scrollLogContainerToBottom()

    this.getAPICalls("all", this.key, (result) => {
      this.createChart(
        result.map(i => this.datePipe.transform(new Date(i.timestamp), "dd/MM HH:mm")!), 
        result.map(i => i.amount),
        this.chartAllRef,
        this._apiCallAllChart,
        "Alle endpoints",
        'API calls alle endpoints',
        (chartCanvas, config) => { this._apiCallAllChart = new Chart(chartCanvas, config)}
      )
    })

    this.getAPICalls("vacancies", this.key, (result) => {
      this.createChart(
        result.map(i => this.datePipe.transform(new Date(i.timestamp), "dd/MM HH:mm")!), 
        result.map(i => i.amount),
        this.chartVacanciesRef,
        this._apiCallVacanciesChart,
        "GET /vacancies",
        'API calls GET /vacancies',
        (chartCanvas, config) => { this._apiCallVacanciesChart = new Chart(chartCanvas, config)}
      )
    })

    this.getAPICalls("direct-apply", this.key, (result) => {
      this.createChart(
        result.map(i => this.datePipe.transform(new Date(i.timestamp), "dd/MM HH:mm")!), 
        result.map(i => i.amount),
        this.chartDirectApplyRef,
        this._apiCallDirectApplyChart,
        "POST /direct-apply",
        'API calls GET /direct-apply',
        (chartCanvas, config) => { this._apiCallDirectApplyChart = new Chart(chartCanvas, config)}
      )
    })
  }

  private createChart(labels: string[], dataValues: number[], chartRef: ElementRef, chart: Chart, title: string, label: string, callback: (chartCanvas: HTMLCanvasElement, config: any) => void) {
    if (!chartRef) { return; } 
    
    const chartCanvas: HTMLCanvasElement = chartRef.nativeElement

    if (chart) {
      chart.data.labels = labels
      chart.data.datasets[0].data = dataValues
      chart.update()
    } else {
      const data = {
        labels: labels,
        datasets: [
          {
            label: label,
            data: dataValues,
            borderColor: 'rgb(117, 182, 231)',
            fill: true,
            backgroundColor: (context: any) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
          
             // This case happens on initial chart load
             if (!chartArea) return;
             return this.getGradient(ctx, chartArea);
          },
          }
        ]
      };

      const config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            filler: {
              propagate: false,
            },
            title: {
              display: true,
              text: title,
              color: "white",
            },
            legend: {
              display: false,
            }
          },
          pointBackgroundColor: '#fff',
          radius: 2,
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              grid: {
                color: "rgb(75 75 75)"
              },
              ticks: {
                color: "white",
                // stepSize: 2,
                maxTicksLimit: 12,
                autoSkip: true,
                // maxRotation: 90,
                // minRotation: 90,
              }
            },
            y: {
              beginAtZero: true,
              grid: {
                color: "rgb(75 75 75)"
              },
              ticks: {
                color: "white",
                // callback: (value: number) => {
                //   return Math.floor(value)
                // },
              },
            },
          }
        },
      };

      callback(chartCanvas, config as any)
    }
  }

  private getGradient(ctx: any, chartArea: any) {
    let gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    gradient.addColorStop(0.9, "rgba(117, 182, 231, 0.9)");
    gradient.addColorStop(0, "transparent");
    return gradient;
  }

  private getAPICalls(endpoint: string, key: string, callback: (result: { id: number; amount: number; endpoint: string; timestamp: string }[]) => void) {
    this.httpClient.get<{ id: number, amount: number, endpoint: string, timestamp: string }[]>(`${Utils.getApiUrl()}/api-call`, {
      params: {
        key,
        endpoint,
      }
    }).pipe(takeUntil(this._unsubscribeAll))
    .subscribe(result => {
      if (result && Array.isArray(result)) {
        this.hasChartData = true
        this.changeDetectorRef.detectChanges()
        callback(result)
      }
    })
  }

  private scrollLogContainerToBottom(init: boolean = false) {
    if (this.logContainerRef) {
      const container = this.logContainerRef.nativeElement;
      const shouldScroll = container.scrollTop + container.clientHeight >= container.scrollHeight - 300;

      if (shouldScroll) {
        container.scrollTop = container.scrollHeight;
      }

      if (init) {
        container.scrollTop = container.scrollHeight;
      }
    }
  }

  public clear() {
    this.formSearch?.setValue("")
  }

  public hasSearch() {
    return this.formSearch?.value.trim().length > 0
  }

  public navigateTo(menuItem: string, isMobileMenu: boolean = false): void {
    if(menuItem) {
      setTimeout(() => {
        this.router.navigate([`../${menuItem.toLowerCase()}`], { relativeTo: this.activatedRoute })
      }, this.sharedService.transitionDelay)
    }
  }

  private scrollFade(): void {
    const container = this.containerRef.nativeElement as HTMLElement

    if(container && container.getBoundingClientRect().y < window.innerHeight && container.classList.contains("scroll-fade")) {
      container.classList.remove("scroll-fade")
    }
  }

  private setMobileBreakpoint() {
    this.isMobile = this.breakpointObserver.isMatched('(max-width: 767px)');
    this.breakpointObserver.observe('(max-width: 767px)')
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(result => {
      if (result.matches) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    })
  }
}
