import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[toUppercase]',
})
export class UppercaseDirective {
  constructor(private elementRef: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange() {
    const value = this.elementRef.nativeElement.value.toUpperCase();
    this.control.control?.setValue(value, { emitEvent: false });
  }
}
