<div class="privacy"></div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text">Privacy statement</div>
  </div>
</div>

<div class="container-1">
  <div #containerRef class="container-2 scroll-fade">
    <div class="left">
      <!-- <div class="title">Blauwtand</div> -->
      <div class="information">
        <div>
          <div class="paragraph-1">In dit Privacy Statement treft u aan hoe wij met uw persoonsgegevens omgaan.  Blauwtand IT Detachering heeft de bescherming van de persoonlijke gegevens van haar stakeholders hoog in het vaandel staan. Daarom worden alle persoonsgegevens zorgvuldig behandeld en beveiligd conform de eisen vanuit de AVG.</div>
          <div class="paragraph-1">In dit Privacy Statement worden de onderstaande onderwerpen uitgelegd: </div>
          <div class="bullet" *ngFor="let bullet of bullets_1">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="header-1">Wie zijn wij?</div>
          <div class="paragraph-1">Blauwtand IT Detachering is gevestigd te Zoetermeer. Blauwtand IT-Detachering B.V. houdt zich bedrijfsmatig voornamelijk bezig met het detacheren en bemiddelen van IT-Professionals aan bedrijven en andere instellingen in wat voor (rechts)vorm dan ook, welke introductie ten doel heeft dat de IT-Professional direct of indirect via een (tijdelijke) arbeidsovereenkomst, managementovereenkomst, ZZP-/detacheringsbasis, deta-vast overeenkomst of via welke andere contractuele/juridische constructie dan ook, werkzaam komt te zijn/arbeid gaat verrichten voor de Opdrachtgever. Blauwtand IT detachering is “verwerking verantwoordelijke” in de zin van de AVG voor de verwerkingen ervan.</div>
          <div class="header-1">Wanneer verzamelen wij uw persoonsgegevens?</div>
          <div class="paragraph-1">Met persoonsgegevens wordt alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon bedoeld. Wij verzamelen onder andere op de volgende manieren persoonsgegevens:</div>
          <div class="bullet" *ngFor="let bullet of bullets_2">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="header-1">Waarom verzamelen wij uw persoonsgegevens?</div>
          <div class="paragraph-1">Wij verzamelen en verwerken uw gegevens voor onderstaande doeleinden:</div>
          <div class="bullet" *ngFor="let bullet of bullets_3">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="paragraph-1">Wij verwerken uw persoonsgegevens op basis van één of meerdere van de volgende juridische grondslagen.</div>
          <div class="bullet" *ngFor="let bullet of bullets_4">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="header-1">Welke persoonsgegevens verzamelen wij van u?</div>
          <div class="paragraph-1">Blauwtand IT Detachering kan onderstaande persoonsgegevens van u verwerken als u werkzoekende, kandidaat, gedetacheerde, (tijdelijke) werknemer, zelfstandig ondernemer en/of zakelijke relatie bent van Blauwtand IT Detachering. U bent zelf verantwoordelijk dat wij de beschikking hebben over uw juiste en relevante gegevens.</div>
          <div class="italic">Websitebezoeker</div>
          <div class="bullet" *ngFor="let bullet of bullets_5">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="italic">Werkzoekende of kandidaat</div>
          <div class="bullet" *ngFor="let bullet of bullets_6">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="italic">Gedetacheerde, (tijdelijke) werknemer en zelfstandig ondernemer</div>
          <div class="paragraph-1">Indien u voor Blauwtand IT Detachering of haar opdrachtgevers werkt/heeft gewerkt, kan Blauwtand IT Detachering onderstaande gegevens verwerken:</div>
          <div class="bullet" *ngFor="let bullet of bullets_7">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="italic">Zakelijke relatie </div>
          <div class="paragraph-1">Blauwtand IT Detachering verwerkt de persoonsgegevens van betrokkenen die werkzaam zijn voor ondernemingen waarmee wij zaken doen, zoals medewerkers van opdrachtgevers, leveranciers, verkopers en andere stakeholders. We kunnen de volgende persoonsgegevens van zakelijke relaties verwerken:</div>
          <div class="bullet" *ngFor="let bullet of bullets_8">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="header-1">Met wie kunnen wij uw persoonsgegevens delen? </div>
          <div class="paragraph-1">Blauwtand IT Detachering kan uw persoonsgegevens doorgeven aan andere Blauwtand IT Detachering entiteiten, haar opdrachtgevers, leveranciers en onderaannemers die namens Blauwtand IT Detachering diensten verlenen of opdrachten uitvoeren (verwerkers), overheidsinstanties en andere zakelijke relaties. Ook verstrekken wij gegevens in gevallen waarin wij daartoe worden verplicht, bijvoorbeeld door een gerechtelijk bevel of een gerechtelijk vonnis.</div>
          <div class="paragraph-1">Derden aan wie wij uw persoonsgegevens verstrekken zijn zelf verantwoordelijk voor de naleving van de privacywetgeving. Blauwtand IT Detachering is niet verantwoordelijk noch aansprakelijk voor de verwerking van uw persoonsgegevens door deze derden. Voor zover een derde uw persoonsgegevens verwerkt in de hoedanigheid van een verwerker van Blauwtand IT Detachering, sluit Blauwtand IT Detachering met een dergelijke derde een verwerkingsovereenkomst af die aan de in de AVG omschreven eisen voldoet.</div>
          <div class="italic">Verwerken wij u gegevens buiten Nederland?</div>
          <div class="paragraph-1">Blauwtand IT Detachering deelt geen persoonsgegevens met het buitenland</div>
          <div class="header-1">Uw rechten </div>
          <div class="paragraph-1">U beschikt als betrokkene over de volgende rechten:</div>
          <div class="bullet" *ngFor="let bullet of bullets_9">
            <icon name="navigate_next"></icon>
            <div class="bullet-text">{{bullet}}</div>
          </div>
          <div class="margin-20"></div>
          <div class="header-1">Beveiliging </div>
          <div class="paragraph-1">Blauwtand IT Detachering doet er alles aan om uw persoonsgegevens optimaal te beveiligen tegen onrechtmatig gebruik. Blauwtand IT Detachering heeft passende technische en organisatorische maatregelen genomen om uw persoonsgegevens te beveiligingen tegen ongeoorloofde of onrechtmatige verwerking en tegen verlies, vernietiging, schade, aanpassingen of bekendmaking. Mocht u vragen hebben over de beveiliging van uw persoonsgegevens, of als er aanwijzingen zijn van misbruik, wordt u verzocht om contact met ons op te nemen via <span class="email" (click)="email('privacy@blauwtand.com')">privacy@blauwtand.com</span>.</div>
          <div class="header-1">Technische informatie en cookies </div>
          <div class="italic">Technische informatie</div>
          <div class="paragraph-1">De website van Blauwtand IT Detachering verzamelt automatisch bepaalde informatie over de (apparaten van) bezoekers van onze websites, zoals bijvoorbeeld het Internet Protocol (IP)- adres van uw computer, het IP-adres van uw Internet Service Provider, datum en tijd van toegang tot de website, het internetadres van de website vanwaar u rechtstreeks bent doorgelinkt naar onze website, het besturingssysteem dat u gebruikt, de onderdelen van de website die u bezoekt, de pagina’s van de website die u heeft bezocht en de informatie die u heeft bekeken, informatie over het type apparaat wat u gebruikt voor uw bezoek aan de site, de geo-locatie waar u zich bevindt en het materiaal dat u opstuurt of downloadt van de website. Deze technische informatie wordt gebruikt voor het beheer van de website en systeembeheerder, en om de kwaliteit en effectiviteit van de website te verbeteren.</div>
          <div class="italic">Cookies en soortgelijke technieken </div>
          <div class="paragraph-1">De website van Blauwtand IT Detachering maakt verder gebruik van cookies en soortgelijke technieken. U kunt hierover meer lezen in ons Cookiebeleid.</div>
          <div class="header-1">Bewaartermijn</div>
          <div class="paragraph-1">Blauwtand IT Detachering bewaart uw persoonsgegevens niet langer dan nodig is voor het bereiken van de in dit Privacystatement genoemde doeleinden dan wel om aan wet- en regelgeving te voldoen. Blauwtand IT Detachering bewaart kandidaatgegevens met toestemming twee jaar na de verleende toestemming, indien u uw toestemming wilt intrekken dan kan dat via <span class="email" (click)="email('privacy@blauwtand.com')">privacy@blauwtand.com</span>.</div>
          <div class="header-1">Vragen, opmerkingen of klachten</div>
          <div class="paragraph-1">Hebt u vragen, opmerkingen of klachten over de bescherming van uw persoonsgegevens door Blauwtand IT Detachering, dan kunt u per e-mail contact opnemen met onze Privacy Officer via <span class="email" (click)="email('privacy@blauwtand.com')">privacy@blauwtand.com</span>, of een brief sturen naar Blauwtand IT Detachering, t.a.v. R.F. Enze (Privacy), 2719 HA te Zoetermeer.</div>
          <div class="header-1">Wijzigingen</div>
          <div class="paragraph-1">Blauwtand IT Detachering kan van tijd tot tijd om uiteenlopende redenen veranderingen, aanvullingen of wijzigingen aanbrengen in haar Privacystatement. Het meest actuele Privacystatement is te allen tijde in te zien op de website van Blauwtand IT Detachering.</div>
        </div>
      </div>
    </div>
  </div>
</div>

