<div class="employers">
</div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text">Open sollicitatie</div>
  </div>
</div>
<div #spinnerRef class="spinner" *ngIf="isLoadingJob">
  <mat-spinner [diameter]="isMobile ? 50 : 75"></mat-spinner>
</div>
<div #viewJobRef class="view-job" [formGroup]="form" *ngIf="!isLoadingJob">
  <div class="job-container">
    <div class="vacancies">
      <div #vacancyRef class="vacancy">
        <div class="applied" *ngIf="applied">
          <div class="applied-header">
            Sollicitatie ontvangen!
          </div>
          <div class="applied-paragraph">
            Je sollicitatie is successvol ontvangen. Wij zullen binnenkort contact met je opnemen.
          </div>
          <div>
            <button mat-raised-button color="primary" class="no-shadow" (click)="navigateTo('home')">
              <span>We gaan vanzelf terug in <span class="countdown">{{countdown}}</span>s</span>
              <icon name="navigate_next"></icon>
            </button>
          </div>
        </div>
        <ng-container *ngIf="!applied">
        <!-- <div class="vacancy-header">Open sollicitatie</div> -->
        <!-- <div class="vacancy-filter-container">
          <div class="vacancy-filter-left">
            <div class="vacancy-filter-row">
              <icon name="location_on"></icon>
              <div class="vacancy-filter-row-container">
                <span>Locatie</span>
                <span class="vacancy-type">{{job?.location?.city}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="business"></icon>
              <div class="vacancy-filter-row-container">
                <span>Functiegebieden</span>
                <div class="vacancy-type">{{mapIndustries(job)}}</div>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="school"></icon>
              <div class="vacancy-filter-row-container">
                <span>Opleidingsniveau</span>
                <span class="vacancy-type">{{job?.education}}</span>
              </div>
            </div>
          </div>
          <div class="vacancy-filter-right">
            <div class="vacancy-filter-row">
              <icon name="euro"></icon>
              <div class="vacancy-filter-row-container">
                <span>Salaris</span>
                <span class="vacancy-type">{{parseSalary(job?.salary)}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="schedule"></icon>
              <div class="vacancy-filter-row-container">
                <span>Dienstverband</span>
                <span class="vacancy-type">{{job?.employment}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="hourglass"></icon>
              <div class="vacancy-filter-row-container">
                <span>Uren</span>
                <span class="vacancy-type">{{parseHours(job)}}</span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="vacancy-description">
          <mat-error #resumeErrorRef class="resume-error hidden">Upload een CV.</mat-error>
          <div class="upload-files">
            <div #resumeRef class="resume">
              <div class="resume-upload">
                <div class="resume-uploaded" *ngIf="resume">
                  <div class="resume-files" (click)="deleteResume($event)">
                    <icon name="file"></icon>
                    <div class="resume-files-text">{{ resume.name }}</div>
                  </div>
                </div>
                <ng-container *ngIf="!resume">
                  <div class="resume-text" *ngIf="dropResume">
                    Drop je CV hier!
                  </div>
                  <ng-container *ngIf="!dropResume">
                    <input type="file" name="resume" id="resume" accept=".pdf,.doc,.docx"(change)="resumeChange($event)" multiple>
                    <button mat-raised-button class="resume-button"  color="primary" class="button-color-primary no-shadow">
                      <label class="resume-input" for="resume">
                        <div>Upload CV</div>
                        <icon name="file_upload"></icon>
                      </label>
                    </button>
                    <div *ngIf="!isMobile" class="resume-text">of drop je bestand hier!</div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div #motivationLetterRef class="resume">
              <div class="resume-upload">
                <div class="resume-uploaded" *ngIf="motivationLetter">
                  <div class="resume-files" (click)="deleteMotivationLetter($event)">
                    <icon name="file"></icon>
                    <div class="resume-files-text">{{ motivationLetter.name }}</div>
                  </div>
                </div>
                <ng-container *ngIf="!motivationLetter">
                  <div class="resume-text" *ngIf="dropMotivationLetter">
                    Drop je motivatiebrief hier!
                  </div>
                  <ng-container *ngIf="!dropMotivationLetter">
                    <input type="file" name="motivationLetter" id="motivationLetter" accept=".pdf,.doc,.docx"(change)="motivationLetterChange($event)" multiple>
                    <button mat-raised-button class="resume-button"  color="primary" class="button-color-primary no-shadow">
                      <label class="resume-input" for="motivationLetter">
                        <div>Upload motivatiebrief</div>
                        <icon name="file_upload"></icon>
                      </label>
                    </button>
                    <div *ngIf="!isMobile" class="resume-text">of drop je bestand hier!</div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="resume-types">
            Ondersteunde bestandstypen: .pdf, .doc, .docx (max. 10 MB)
          </div>
          <mat-form-field class="direct-apply-input-field" appearance="outline">
            <mat-label>Voornaam</mat-label>
            <input matInput maxlength="50" type="text" formControlName="firstName">
            <button *ngIf="firstName" matSuffix mat-icon-button aria-label="Clear" (click)="clearFirstName()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="direct-apply-input-field" appearance="outline">
            <mat-label>Achternaam</mat-label>
            <input matInput maxlength="50" type="text" formControlName="lastName">
            <button *ngIf="lastName" matSuffix mat-icon-button aria-label="Clear" (click)="clearLastName()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="direct-apply-input-field" appearance="outline">
            <mat-label>Emailadres</mat-label>
            <input matInput placeholder="bv. info@blauwtand.com" type="text" formControlName="email" [pattern]="emailPattern">
            <button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" (click)="clearEmail()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="this.form.get('phone')?.invalid">Vul een geldig emailadres in. Bijvoorbeeld: info@blauwtand.com.</mat-error>
          </mat-form-field>
          <mat-form-field class="direct-apply-input-field" appearance="outline">
            <mat-label>Adres</mat-label>
            <input matInput maxlength="50" placeholder="bv. Goudenregenzoom 17" type="text" formControlName="address">
            <button *ngIf="address" matSuffix mat-icon-button aria-label="Clear" (click)="clearAddress()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="direct-apply-input-field" appearance="outline">
            <mat-label>Plaats</mat-label>
            <input matInput maxlength="50" placeholder="bv. Zoetermeer" type="text" formControlName="location">
            <button *ngIf="location" matSuffix mat-icon-button aria-label="Clear" (click)="clearLocation()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="direct-apply-input-field" appearance="outline">
            <mat-label>Postcode</mat-label>
            <input matInput placeholder="bv. 2719 HA" type="text" formControlName="postalCode" toUppercase>
            <button *ngIf="postalCode" matSuffix mat-icon-button aria-label="Clear" (click)="clearPostalCode()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="this.form.get('postalCode')?.invalid">Vul een geldige postcode in. Bijvoorbeeld: 2717 HA.</mat-error>
          </mat-form-field>
          <mat-form-field class="direct-apply-input-field" appearance="outline">
            <mat-label>Telefoonnummer</mat-label>
            <input matInput type="text" formControlName="phone">
            <button *ngIf="phone" matSuffix mat-icon-button aria-label="Clear" (click)="clearPhone()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="this.form.get('phone')?.invalid">Vul een geldig Nederlands telefoonnummer in. Bijvoorbeeld: +31 0798885551.</mat-error>
          </mat-form-field>
          <div class="privacy-checkbox-container">
            <mat-checkbox #checkboxErrorRef required class="privacy-checkbox" color="primary" (change)="changeCheckbox($event)"></mat-checkbox>
            <div class="privacy-checkbox-text">Ja, ik heb gezien dat Blauwtand een <span class="privacy-statement" (click)="navigateTo('privacy-statement')">privacy statement</span> heeft. *</div>
          </div>
          
          <div class="vacancy-apply">
            <button [disabled]="isSendingApplication" class="vacancy-apply-button" mat-raised-button color="primary" class="button-color-primary no-shadow" (click)="apply()">
              <div>{{sendApplicationText}}</div>
              <icon *ngIf="!isSendingApplication" name="navigate_next"></icon>
              <mat-icon class="spinner-send" *ngIf="isSendingApplication">
                <mat-spinner color="primary" diameter="20"></mat-spinner>
              </mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
        
        <!-- <div *ngIf="job?.description" class="vacancy-description">
          <span class="vacancy-text" [innerHTML]="job?.description"></span>
        </div> -->
        <!-- <div class="date">
          <div class="vacancy-time">Geplaatst: {{dateToDatePosted(job?.dateCreated)}}</div>
        </div> -->
        <!-- <div [innerHTML]="jsonLD"></div> -->
      </div>
    </div>
  </div>
  <!-- <div #buttonsRef class="buttons">
    <button mat-raised-button color="primary" class="button-color-primary" (click)="backToJobs()">
      <span>Terug naar vacature overzicht</span>
      <icon name="navigate_next"></icon>
    </button>
  </div> -->
</div>
