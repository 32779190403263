import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Utils } from './utils';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild("containerRef") public containerRef: ElementRef
  public phoneCall = Utils.phoneCall
  public phoneNumber = Utils.phoneNumber
  public email = Utils.email
  public whatsapp = Utils.whatsapp
  public whatsAppNumber = Utils.whatsAppNumber
  
  public constructor(
    private titleService: Title,
  ) {}
  
  public ngOnInit(): void {
    window.scrollTo(0, 0)
    this.titleService.setTitle("Blauwtand — Contact")
  }

  public async ngAfterViewInit(): Promise<void> {
    await Utils.timeout()
    window.addEventListener("scroll", () => {
      this.scrollFade()
    })
    this.scrollFade()
  }

  private scrollFade(): void {
    const container = this.containerRef.nativeElement as HTMLElement

    if(container && container.getBoundingClientRect().y < window.innerHeight && container.classList.contains("scroll-fade")) {
      container.classList.remove("scroll-fade")
    }
  }

  public googleMapsOffice(): void {
    window.open("https://www.google.com/maps/place/Blauwtand/@52.0420598,4.4682062,17z/data=!3m1!4b1!4m5!3m4!1s0x47c5c9419435827d:0xdc71f13147a8bac!8m2!3d52.0420565!4d4.4703949")
  }
}
