import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { RoutingModule } from './routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { IconComponent } from './icon.component';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ContactComponent } from './contact.component';
import { NotFoundComponent } from './not-found.component';
import { MainComponent } from './main.component';
import { AboutComponent } from './about.component';
import { EmployersComponent } from './employers.component';
import { VacanciesComponent } from './vacancies.component';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { SharedService } from './shared.service';
import { ViewJobComponent } from './view-job.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { PrivacyComponent } from './privacy.component';
import { DirectApplyComponent } from './direct-apply.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApplyComponent } from './apply.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { customPaginator } from './providers/custompaginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SocketIoService } from './socketio.service';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from 'client/environments/environment';
import { LogsComponent } from './logs/logs.component';
import { DatePipe } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutModule } from '@angular/cdk/layout';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AppliedComponent } from './components/applied.component';
import { Router } from '@angular/router';
import { UppercaseDirective } from './directives/to-uppercase.directive';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    FooterComponent,
    IconComponent,
    ContactComponent,
    AboutComponent,
    EmployersComponent,
    VacanciesComponent,
    ViewJobComponent,
    DirectApplyComponent,
    ApplyComponent,
    AppliedComponent,
    PrivacyComponent,
    NotFoundComponent,
    LogsComponent,
    UppercaseDirective,
  ],
  imports: [
    BrowserModule,
    RoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatIconModule,
    HttpClientModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatRippleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    LayoutModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
    NgxGoogleAnalyticsRouterModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId,
    }),
    SocketIoModule.forRoot({
      url: environment.socketUrl,
      options: {
        upgrade: true,
        path: '/socket.io',
      },
    }),
  ],
  providers: [
    SharedService,
    SocketIoService,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: customPaginator() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
