<div class="employers">
</div>
<div [innerHTML]="jsonLD"></div>
<div class="title-container-1">
  <div class="title-container-2">
    <div class="search-text"></div>
  </div>
</div>

<div class="container-1">
  <div #notAvailableRef class="container-2 scroll-fade">
    <div class="left">
      <div class="title">Vacature niet gevonden</div>
      <div class="information">
        <div>Sorry, we hebben de vacature niet kunnen vinden! Maak je echter niet druk, we gaan vanzelf terug naar het vacatureoverzicht.</div>
      </div>
      <div>
        <button mat-raised-button color="primary" class="google-maps no-shadow" (click)="navigateTo('home')">
          <span>We gaan vanzelf terug in <span class="countdown">{{countdown}}</span>s</span>
          <icon name="navigate_next"></icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div #viewJobRef class="view-job" *ngIf="!isLoadingJob && isPublished">
  <div class="job-container">
    <div class="vacancies">
      <div #vacancyRef class="vacancy">
        <div class="vacancy-header">{{job.title}}</div>
        <div class="vacancy-apply">
          <button class="vacancy-apply-button" mat-raised-button color="primary" class="button-color-primary no-shadow" (click)="directApply($event)">
            <div>Solliciteer direct</div>
            <icon name="navigate_next"></icon>
          </button>
        </div>
        <div class="vacancy-filter-container">
          <div class="vacancy-filter-left">
            <div class="vacancy-filter-row">
              <icon name="location_on"></icon>
              <div class="vacancy-filter-row-container">
                <span>Locatie</span>
                <span class="vacancy-type">{{job.location.city}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="business"></icon>
              <div class="vacancy-filter-row-container">
                <span>Functiegebieden</span>
                <div class="vacancy-type">{{mapIndustries(job)}}</div>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="school"></icon>
              <div class="vacancy-filter-row-container">
                <span>Opleidingsniveau</span>
                <span class="vacancy-type">{{job.education}}</span>
              </div>
            </div>
          </div>
          <div class="vacancy-filter-right">
            <div class="vacancy-filter-row">
              <icon name="euro"></icon>
              <div class="vacancy-filter-row-container">
                <span>Salaris</span>
                <span class="vacancy-type">{{parseSalary(job.salary)}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="schedule"></icon>
              <div class="vacancy-filter-row-container">
                <span>Dienstverband</span>
                <span class="vacancy-type">{{job.employment}}</span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <icon name="hourglass"></icon>
              <div class="vacancy-filter-row-container">
                <span>Uren</span>
                <span class="vacancy-type">{{parseHours(job)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="job?.description" class="vacancy-description">
          <span class="vacancy-text" [innerHTML]="job.description"></span>
        </div>
        <div class="vacancy-apply second">
          <button class="vacancy-apply-button" mat-raised-button color="primary" class="button-color-primary no-shadow" (click)="directApply($event)">
            <div>Solliciteer direct</div>
            <icon name="navigate_next"></icon>
          </button>
        </div>
        <div class="date">
          <div class="vacancy-time">Geplaatst: {{dateToDatePosted(job.publicationDate)}}</div>
          <!-- <div class="vacancy-time">Laatst geupdate: {{dateToDatePosted(job?.dateModified)}}</div> -->
        </div>
      </div>
    </div>
    <div class="more-info">
      <div class="recruiter" #recruiterRef>
        <div class="recruiter-title">Recruiter — {{job.recruiter?.firstName}} {{job.recruiter?.lastName}}</div>
        <div class="recruiter-content">
          <!-- <img class="recruiter-image" src="assets/avatar_unknown.png"> -->
          <div class="recruiter-info">
            <div class="info">
              <icon name="call"></icon>
              <div class="info-text" (click)="phoneCall(job.recruiter?.phoneNumber || '')">+{{job.recruiter?.phoneNumber}}</div>
            </div>
            <div class="info">
              <icon name="email" ></icon>
              <div class="info-text" (click)="email(job.recruiter?.userName || '')">{{job.recruiter?.userName}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="related-jobs" >
        <div #relatedJobsTitleRef class="related-jobs-title">
          <div class="text">Gerelateerde vacatures</div>
          <mat-button-toggle-group [ngModel]="relatedVacanciesToggle" (change)="relatedVacanctiesToggle($event)" class="toggle-group" name="toggleGroup">
            <mat-button-toggle value="publicationDate">Publicatie datum</mat-button-toggle>
            <mat-button-toggle value="alphabetic">A-Z</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div #relatedJobsContainerRef class="related-jobs-container" *ngIf="relatedJobs.length > 0">
          <div 
            matRipple 
            matRippleColor="rgb(47, 47, 47, 0.1)" 
            class="related-job" 
            *ngFor="let relatedJob of relatedJobs"
            (click)="viewJob($event, relatedJob)">
            <div class="related-job-content">
              <div>{{relatedJob.title}}</div>
              <icon class="related-job-icon" name="navigate_next" ></icon>
            </div>
          </div>
        </div>
        <div class="no-related-jobs" *ngIf="relatedJobs.length === 0 && isLoadingRelatedJobs">
          <div>Zoeken naar gerelateerde vacatures...</div>
          <mat-spinner class="no-related-jobs-spinner" [diameter]="20"></mat-spinner>
        </div>
        <div class="no-related-jobs" *ngIf="relatedJobs.length === 0 && !isLoadingRelatedJobs">
          We hebben geen gerelateerde vacatures kunnen vinden.
        </div>
      </div>
    </div>
  </div>
</div>

<div #viewJobRef class="view-job" *ngIf="isLoadingJob">
  <div class="job-container">
    <div class="vacancies">
      <div #vacancyRef class="vacancy">
        <div class="vacancy-header-skeleton skeleton-box"></div>
        <div class="vacancy-apply">
          <div class="vacancy-apply-button-skeleton skeleton-box"></div>
        </div>
        <div class="vacancy-filter-container">
          <div class="vacancy-filter-left">
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
          </div>
          <div class="vacancy-filter-right">
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
            <div class="vacancy-filter-row">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <div class="vacancy-filter-row-container">
                <span class="vacancy-type-skeleton skeleton-box"></span>
                <div class="margin-small-skeleton"></div>
                <span class="vacancy-type-skeleton skeleton-box"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="vacancy-description">
          <div class="job-description-header-skeleton skeleton-box"></div>
          <div class="margin-skeleton"></div>
          <div class="job-description-sub-header-skeleton skeleton-box"></div>
          <div class="margin-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 25%;"></div>
          <div class="margin-skeleton"></div>
          
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 99%;"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 60%;"></div>
          <div class="margin-skeleton"></div>

          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 99%;"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 75%;"></div>
          <div class="margin-skeleton"></div>

          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 99%;"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 98%;"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
          <div class="job-description-paragraph-skeleton skeleton-box" style="width: 30%;"></div>
          <div class="margin-skeleton"></div>

          <ng-container *ngFor="let i of [0, 1, 2]">
            <div class="job-description-sub-header-skeleton skeleton-box"></div>
            <div class="margin-skeleton"></div>

            <div class="job-description-bullet-container-skeleton">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 15px;"></div>
              <div class="job-description-paragraph-skeleton skeleton-box" style="width: 80%;"></div>
            </div>
            <div class="margin-small-skeleton"></div>
            <div class="job-description-bullet-container-skeleton">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 15px;"></div>
              <div class="job-description-paragraph-skeleton skeleton-box" style="width: 60%;"></div>
            </div>
            <div class="margin-small-skeleton"></div>
            <div class="job-description-bullet-container-skeleton">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 15px;"></div>
              <div class="job-description-paragraph-skeleton skeleton-box" style="width: 70%;"></div>
            </div>
            <div class="margin-small-skeleton"></div>
            <div class="job-description-bullet-container-skeleton">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 15px;"></div>
              <div class="job-description-paragraph-skeleton skeleton-box" style="width: 65%;"></div>
            </div>
            <div class="margin-skeleton"></div>
          </ng-container>
        </div>
        
        <div class="vacancy-apply second">
          <div class="vacancy-apply-button-skeleton skeleton-box"></div>
        </div>
        <div class="date">
          <div class="vacancy-time">
            <div class="job-description-paragraph-skeleton skeleton-box" style="width: 20%;"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="more-info">
      <div class="recruiter">
        <div class="job-description-sub-header-skeleton skeleton-box" style="height:40px;"></div>
        <div class="margin-small-skeleton"></div>
        <div class="recruiter-content">
          <div class="recruiter-info">
            <div class="info">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <span class="vacancy-type-skeleton skeleton-box"></span>
            </div>
            <div class="info">
              <div class="job-description-bullet-skeleton skeleton-box" style="width: 20px; height: 20px;"></div>
              <span class="vacancy-type-skeleton skeleton-box" style="width:200px;"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="related-jobs">
        <div class="related-jobs-title">
          <div class="job-description-sub-header-skeleton skeleton-box" style="height:40px;"></div>
          <div class="margin-skeleton"></div>
        </div>
        <div class="no-related-jobs first">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
        <div class="no-related-jobs last">
          <div class="job-description-paragraph-skeleton skeleton-box"></div>
          <div class="margin-small-skeleton"></div>
        </div>
      </div>
    </div>
  </div>
</div>