<div #footerRef class="footer">
  <div class="top scroll-fade">
    <div class="logo">
      <icon name="blauwtand"></icon>
    </div>
    <div class="contact">
      <div class="contact-child">
        <icon name="call"></icon>
        <div (click)="phoneCall('31798080320')">{{ phoneNumber }}</div>
      </div>
      <div class="contact-child">
        <icon name="whatsapp"></icon>
        <div (click)="whatsapp('31798080328')">{{ whatsAppNumber }}</div>
      </div>
      <div class="contact-child">
        <icon name="email"></icon>
        <div (click)="email('info@blauwtand.com')">info&#64;blauwtand.com</div>
      </div>
    </div>
    <div class="socials">
      <icon name="facebook" (click)="redirectTo('facebook')"></icon>
      <!-- <icon name="twitter"  (click)="redirectTo('twitter')"></icon> -->
      <icon name="linkedin" (click)="redirectTo('linkedin')"></icon>
      <icon name="instagram" class="insta"  (click)="redirectTo('instagram')"></icon>
    </div>
  </div>
  <div class="bottom scroll-fade">
    <div>COPYRIGHT © {{currentYear}} — BLAUWTAND ALL RIGHTS RESERVED.</div>
    <div class="kvk">KvK nr. 84981741.</div>
    <div class="privacy-policy" (click)="navigateTo('privacy-statement')">Privacy statement.</div>
  </div>
</div>
